import {Component} from 'react';
import Header from '../Header.js';
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import {
  getHomePrograms, getPrograms, createRobot
} from '../../actions/xhr-actions';
import { Button, KIND, SIZE, SHAPE } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux';
import { Tabs, Tab } from "baseui/tabs";
import {Spinner} from 'baseui/spinner';
import {userFlags} from '../../helpers/user-flags.js';

const defaultRobotPfp = () => {
  const defaultImages = ["/Robot1.png", "/Robot2.png"];
  return defaultImages[Math.floor(Math.random() * defaultImages.length)]
}

class ProgramStore extends Component {
  constructor() {
    super();
    this.state = {
      programAds: [],
      activeKey: '0'
    };
  }
  componentDidMount() {
    this.props.getHomePrograms()
      .then(res => {
        this.setState({programAds: res.res});
      });
  }
  renderCreateProgramLink() {
    if (!userFlags(this.props.me.me, 'programs.create')) {
      return null;
    }
    return (
      <div style={{
        padding: '12px 0'
      }}>
        <Button onClick={() => this.props.history.push('/o/program/create')}>
          {'Create A Program'}
        </Button>
      </div>
    );
  }
  render() {
    return (
      <div style={{
      }}>
        {this.renderCreateProgramLink()}
        <div>
          <Tabs
            onChange={({ activeKey }) => {
              this.setState({activeKey}, () => {
                if (activeKey === "0") {
                  this.props.getHomePrograms()
                    .then(res => {
                      this.setState({programAds: res.res});
                    });
                }
                if (activeKey === "1") {
                  this.props.getPrograms()
                    .then(res => {
                      this.setState({programAds: res.res});
                    });
                }
              });
            }}
            activeKey={this.state.activeKey}
          >
            <Tab title="Home" />
            {this.props.me.me ?
              <Tab title="My Programs" /> : null}
          </Tabs>
        </div>
        <div  style={{
          overflow: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          margin: '0 16px 24px 16px'
        }}>
          {this.props.programStore.isLoadingPrograms ?
            <Spinner /> : null}
          {this.props.programStore.homePrograms.length === 0 &&
            !this.props.programStore.isLoadingPrograms ?
            <div>
              <img style={{
                width: '50%',
                display: 'table',
                filter: 'grayscale(100%)',
                margin: '0 auto'
              }} src={"/Robot1.png"} />
              <div style={{
                display: 'table',
                margin: '0 auto'
              }}>
                <span>{'No Programs. '}
                <a href={'/o/program/create'}>
                  {'Create one.'}
                </a></span>
              </div>
            </div> : null}
          {this.props.programStore.homePrograms.map(p => {
            const sqSize =`${this.props.windowInfo.width < 700 ?
                this.props.windowInfo.width - 48 : Math.max(
                this.props.windowInfo.width / 3,
                240) - 100}px`;
            return (
              <Card
                key={p.uuid}
                overrides={{Root: {style: {
                  width: sqSize,
                  margin: '0 12px 32px 0',
                  wordWrap: 'word'}}}}
                headerImage={{
                  src: p.thumb || defaultRobotPfp(),
                  height: sqSize,
                  width: sqSize,
                  onClick: () => {
                    window.location = `/o/program-view/${p.uuid}`;
                  },
                  style: {objectFit: 'cover'}
                }}
                title={
                  <span>
                    <div>{p.name}</div>
                    <span style={{margin: '0 12px 0 0', display: 'inline-block'}}>
                      {this.props.me.me ?
                        <Button
                          kind={KIND.secondary}
                          size={SIZE.compact}
                          shape={SHAPE.pill}
                          onClick={() => {
                            this.props.createRobot({
                              programUuid: p.uuid,
                              name: p.name
                            }).then((robot) => {
                              console.log(robot.res);
                              window.location = `/o/robot-operation/${robot.res.robotUuid}`
                            });
                          }}>
                          {'Install'}
                        </Button> : null}
                      </span>
                      <span style={{margin: '0 12px 0 0', display: 'inline-block'}}>
                        <a href={`/o/program-view/${p.uuid}`} style={{
                          color: 'initial',
                          textDecoration: 'none',
                          marginLeft: '6px'
                        }}>
                          <Button kind={KIND.secondary}
                            size={SIZE.compact}
                            shape={SHAPE.pill}
                            >
                            {'View'}
                          </Button>
                        </a>
                      </span>
                  </span>}>
                <StyledBody>
                  {p.author ? <div>
                    <strong style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }} onClick={() => {
                      window.location = `/o/users/${p.author.uuid}`
                    }}>
                      {p.author.name}
                    </strong>
                  </div> : null}
                  <div>
                    {`${
                      p.resources && p.resources.reduce((a, resource) => {
                        if (resource.type === 'wifi-motor') {
                          a++;
                        }
                        return a;
                      }, 0)
                    } motors`}
                  </div>
                  <div>
                    {`${
                      p.resources && p.resources.reduce((a, resource) => {
                        if (resource.type === 'camera') {
                          a++;
                        }
                        return a;
                      }, 0)
                    } cameras`}
                  </div>
                  <div>
                    {`${p.files && p.files.length} print files`}
                  </div>
                  <p>
                    {p.description}
                  </p>
                </StyledBody>
                <StyledAction>

                </StyledAction>
              </Card>
            );
          })}
        </div>

      </div>
    );
  }
}

export default connect(s => s, {
  getHomePrograms, createRobot, getPrograms
})(withRouter(ProgramStore));
