const defaultState = {
  devices: {}
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'SOCKET_MESSAGE':
    try {
      const messageJSON = JSON.parse(action.payload.data);
      if (messageJSON.type === 'wifiList') {
        return {
          ...state,
          devices: {
            [messageJSON.deviceUuid]: {
              ...state.devices[messageJSON.deviceUuid],
              wifiState: JSON.parse(messageJSON.data)
            }
          }
        };
      }
    } catch (err) {
      return state;
    }
  default:
    return state;
  }
}
