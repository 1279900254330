import React, {Component} from 'react';
import Header from './Header.js';
import {getUser, getMe, updateUser} from '../actions/xhr-actions.js';
import {uploadProfilePhoto} from '../actions/upload-action.js';
import '../css/auth.css';
import { Button, KIND } from "baseui/button";
import { Input } from "baseui/input";
import { FileUploader } from "baseui/file-uploader";
import { Textarea } from "baseui/textarea";import { Avatar } from "baseui/avatar";
import {connect} from 'react-redux';
import Plus from 'baseui/icon/plus';

//const API_URL = 'https://robots-gateway.uc.r.appspot.com';
const API_URL = 'http://localhost:3000';

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      user: {}
    };
  }
  componentDidMount() {
    this.props.getUser({
      id: this.props.match.params.uuid
    })
    .then(res => {
      this.setState({user: res.res});
    });
  }
  render() {
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <div style={{
            margin: '0 auto',
            display: 'table'
          }}>
            <Avatar
              name={
                this.props.name || ''
              }
              size="scale1400"
              src={this.state.user.profilephoto}
            />
          </div>
          <Button kind={KIND.secondary}
            onClick={() => {
              this.props.getMe()
              .then(res => {
                return this.props.updateUser({
                  ...res.res,
                  friends: [this.props.match.params.uuid]
                });
              });
            }}>
            {'Follow'}
          </Button>
          <strong style={{
            margin: '0 auto',
            display: 'table'
          }}>{this.state.user.name}</strong>
          <p style={{
            whiteSpace: 'break-spaces'
          }}>{this.state.user.summary}</p>
        </div>
      </div>
    );
  }
}

export default connect(s => ({}), {
  getUser, getMe, updateUser
})(UserProfile);
