import {Component} from 'react';
import Header from '../Header.js';
import Plus from '../icons/Plus.js';
import Minus from '../icons/Minus.js';
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import { Button, SIZE, SHAPE, KIND } from "baseui/button";
import {getDevices} from '../../actions/xhr-actions';
import {connect} from 'react-redux';
import { Select } from "baseui/select";
import moment from 'moment';

class RobotResourceSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      selectedItems: Array((this.props.program.resources || []).length)
        .fill(0)
        .map(x => null)
    };
    if (this.props.robotResource && this.props.robotResource.length) {
      this.state.selectedItems = this.props.robotResource
    }
  }
  componentDidMount() {
    this.props.getDevices()
      .then(resp => {
        this.setState({
          devices: resp.res
        });
      });
  }
  renderOnline(d) {
    if (!d || !d.state) {
      return null;
    }
    console.log(Date.now(), moment(d.state.updatedAt).valueOf());
    if (Date.now() - moment(d.state.updatedAt).valueOf() < 100000) {
      return (
        <span style={{marginLeft: '4px'}}>
          <svg height="19" width="19">
            <circle cx="9" cy="9" r="9" fill="#39B200" />
          </svg>
          <strong style={{marginLeft: '8px'}}>{'Online'}</strong>
        </span>)
    }
    return (
      <span style={{marginLeft: '4px'}}>
        <strong>{'Last Online: '}</strong>
        {moment(d.state.updatedAt).fromNow()}
      </span>
    );
  }
  render() {
    return (
      <div style={{
        margin: '12px 0'
      }}>
      <div style={{
        display: 'flex'
      }}>
        {(this.props.program.resources || []).map((pr, i) => {
          const robotResources = [...this.props.robotResource].slice(this.props.program.resources.length);
          return (
            <Card
              key={i}
              overrides={{Root: {style: {
                width: '368px',
                margin: '48px 12px 0 0',
                cursor: 'pointer'}}}}
              title={`${pr.type} ${i+1}`}>
              <StyledBody>
                <Select
                  onChange={params => {
                    const newSelection = [
                      ...this.state.selectedItems
                    ];
                    newSelection[i] = params.value[0]['id'];
                    this.setState({
                      selectedItems: newSelection
                    });
                  }}
                  value={this.state.selectedItems[i]}
                  getOptionLabel={({option}) => {
                    if (!option.device) {
                      return null;
                    }
                    return (
                      <span>
                        {option.device.uuid.slice(0, 4)}
                        {option.device.state ? (
                          <span style={{marginLeft: '4px'}}>
                            <strong>{'Type: '}</strong>
                            {option.device.state.type}
                          </span>
                        ) : null}
                        {this.renderOnline(option.device)}
                      </span>)
                  }}
                  getValueLabel={({option}) => {
                    const device = this.state.devices
                      .find(d => d.uuid === option)
                    if (!device) {
                      return null;
                    }
                    return (
                      <span>
                        {device.uuid.slice(0, 4)}
                        {device.state ? (
                          <span style={{marginLeft: '4px'}}>
                            <strong>{'Type: '}</strong>
                            {device.state.type}
                          </span>
                        ) : null}
                        {this.renderOnline(device)}
                      </span>
                    );
                  }}
                  options={this.state.devices.map(d => ({
                    device: d,
                    id: d.uuid
                  })).filter(x => {
                    return this.state.selectedItems.indexOf(x.id) === -1
                  })}
                  valueKey="id"
                  labelKey="id" />
              </StyledBody>
              <StyledAction>
                <Button
                  size={SIZE.compact}
                  shape={SHAPE.pill}
                  kind={KIND.secondary}
                  onClick={() => {
                    robotResources[i] = this.state.selectedItems[i];
                    this.props.attachDeviceToResource(
                      this.state.selectedItems[i],
                      robotResources)
                  }}>
                  {'Attach'}
                </Button>
              </StyledAction>
            </Card>
          );
        })}
      </div>
      </div>
    );
  }
}

export default connect(null, {
  getDevices
})(RobotResourceSlots);
