import React, {Component} from 'react';
import Header from './Header.js';
import '../css/auth.css';
import {connect} from 'react-redux';

class AuthLoader extends Component {
  render() {
    if (this.props.user) {
      return (
        <div>
          {this.props.children}
        </div>
      )
    }
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <a href="/o/robots">
            <div style={{height: 500}}>
              <img src={"/photo.jpg"} className="App-logo" alt="logo" />
              <div className="Hero-shadow">
                <svg width="400px" height="55" viewBox="0 0 400 400" preserveAspectRatio="none" className="bouncing-shadow">
                  <filter id="blurMe">
                     <feGaussianBlur in="SourceGraphic" stdDeviation="40" />
                   </filter>
                  <circle
                    fill="#000"
                    opacity=".08"
                    cx="200"
                    cy="200"
                    r="140"
                    filter="url(#blurMe)"
                    />
                </svg>
              </div>
            </div>
          </a>
          <p>
            Robotics for regular people.
          </p>
        </div>
      </div>
    );
  }
}

const ConnectedAuthLoader = connect(s => s)(AuthLoader)

export default ConnectedAuthLoader;
