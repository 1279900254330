import './App.css';
import { Button, KIND, SIZE, SHAPE } from "baseui/button";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://orobot-io.myshopify.com/" style={{zIndex: 2}}>
          <div style={{maxHeight: 500}}>
            <img src={"/logo2.png"} className="App-logo" alt="logo"
              />
            <div style={{height: '28px'}}>
              <div className="Hero-shadow">
                <svg width="80%" height="55" viewBox="0 0 400 400" preserveAspectRatio="none" className="bouncing-shadow">
                  <filter id="blurMe">
                     <feGaussianBlur in="SourceGraphic" stdDeviation="40" />
                   </filter>
                  <circle
                    fill="#000"
                    opacity=".08"
                    cx="200"
                    cy="200"
                    r="140"
                    filter="url(#blurMe)" />
                </svg>
              </div>
            </div>
          </div>
        </a>
        <div>
          <Button onClick={() => {
            window.location = '/o/home';
          }}>
            {'Enter App'}
          </Button>
          <span style={{
            marginLeft: 24
          }}>
            <Button
              kind={KIND.secondary}
              onClick={() => {
                window.location = 'https://orobot-io.myshopify.com/';
              }}>
              {'Go to Store'}
            </Button>
          </span>
        </div>
        <div style={{borderRadius: 12, backgroundColor: 'white', padding: 24, zIndex: 2}}>
          <p>
            Robotics for regular people.
          </p>
          <form
            action="https://orobot.us2.list-manage.com/subscribe/post?u=4a1a1fec15ec4f37e647feab5&amp;id=433faef065"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate Hero-form"
            target="_blank"
            novalidate>
            <div style={{paddingRight: '6px'}}>
              <input type="email" placeholder="Email" name="EMAIL" class="required email" id="mce-EMAIL" />
            </div>
            <Button
              type={'submit'}
            >
            {'Join the Mailing List'}
            </Button>
            <div>
              <div style={{
                display: 'table',
                margin: '0 auto',
                fontSize: '14px',
                marginTop: '18px'
              }}>
                <span>{'or'}</span>
              </div>
              <a style={{
                fontSize: '14px'
              }} href={'https://discord.gg/j6BvdnAdct'}>
                {'Join the Discord.'}
              </a>
            </div>
          </form>
          <p style={{
            fontSize: '16px'
          }}>
            <a href="jobs.html">{'Jobs'}</a>
            <span>{' | '}</span>
            <a href="investors.html">{'Investors'}</a>
            <span>{' | '}</span>
            <a href="privacy.html">{'Privacy'}</a>
            <span>{' | '}</span>
            <a href="terms.html">{'Terms'}</a>
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
