import {Component} from 'react';
import {connect} from 'react-redux';
import Header from './Header';
import {uuid} from 'uuidv4';
import {getProgram,
  createRobot,
  createProgram,
  saveProgramData,
  deleteProgram} from '../actions/xhr-actions';
import {StlViewer} from "react-stl-viewer";
import {Button, KIND, SIZE, SHAPE} from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import {userFlags} from '../helpers/user-flags.js';

class ProgramViewPage extends Component {
  constructor() {
    super();
    this.state = {
      program: {
        images: [],
        files: []
      }
    };
  }
  componentDidMount() {
    this.props.getProgram({
      id: this.props.match.params.uuid
    }).then(({res}) => {
      if (!res) {
        return;
      }
      this.setState({
        program: res
      });
    });
  }
  renderAuthorButtons() {
    if (!this.state.program.author || !this.props.me.me ||
      this.props.me.me.userUuid !== this.state.program.author.uuid) {
      return null;
    }
    return (
      <span>
        {userFlags(this.props.me.me, 'programs.create') ?
          <span style={{marginLeft: 12}}>
            <Button
              kind={KIND.primary}
              size={SIZE.compact}
              shape={SHAPE.pill}
              onClick={() => {
                const newUuid = uuid();
                this.props.createProgram().then((res) => {
                  this.props.saveProgramData({
                    ...this.state.program,
                    uuid: res.res.uuid,
                    name: `${this.state.program.name} Clone`
                  });
                })
              }}>
              Clone
            </Button>
          </span> : null}
          <span style={{marginLeft: 12}}>
          <Button
            kind={KIND.primary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {
              window.location = `/o/program-editor/${this.props.match.params.uuid}`
            }}>
            Edit
          </Button>
          </span>
          <span style={{marginLeft: 12}}>
          <Button
            kind={KIND.primary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {
              this.props.deleteProgram({
                uuid: this.props.match.params.uuid
              });
            }}>
            Delete
          </Button>
        </span>
      </span>
    );
  }
  render() {
    return (
      <div>
        <Header />
        <div style={{
          margin: '0 auto',
          display: 'table',
          overflow: 'none'
        }}>
          <div style={{
            backgroundColor: '#e4e4e4'
          }}>
            <div style={{
              display: 'table',
              margin: '0 auto',

            }}>
              <img src={this.state.program.images[0]}
                style={{
                  width: '400px',
                  display: 'block'
                }}/>
            </div>
          </div>
          <div style={{margin: '0 24px'}}>
            <h1>{this.state.program.name}</h1>
            <h3>{this.state.program.author && this.state.program.author.name}</h3>
            <h4>{this.state.program.description}</h4>
            <span>
              <span style={{marginLeft: 12}}>
                {this.props.me.me ?
                  <Button
                    kind={KIND.primary}
                    size={SIZE.compact}
                    shape={SHAPE.pill}
                    onClick={() => {
                      this.props.createRobot({
                        programUuid: this.state.program.uuid,
                        name: this.state.program.name
                      });
                    }}>
                    Install
                  </Button> : null}
                </span>
                {this.renderAuthorButtons()}
            </span>
          </div>
          <div>
            {this.state.program.files.map(fileSrc => {
              return (
                <span style={{
                  display: 'inline-block'
                }}>
                  <StlViewer
                    url={fileSrc}
                    style={{
                      width: '240px',
                      height: '240px'
                    }}
                    modelProps={{
                      color: '#2222ff'
                    }}
                    orbitControls={true}
                  />
                  <div style={{
                    margin: '0 24px'
                  }}>
                    <a
                      href={fileSrc}
                      download>
                      <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}>{'Download'}</Button>
                    </a>
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => s, {
  getProgram,
  createRobot,
  createProgram,
  saveProgramData,
  deleteProgram
})(ProgramViewPage)
