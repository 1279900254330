//const API_URL = 'http://localhost:8080';
//const API_URL = 'https//localhost:3000/api-proxy';
//const API_URL = 'https://robots-gateway.uc.r.appspot.com';
const API_URL = '';

const quipySessKey = '_osess';

function reduxXHR(name, requestOpts) {
  return req => dispatch => new Promise((resolve, reject) => {
    dispatch({
      type: name + '_START',
      payload: {req}
    });
    fetch(API_URL + requestOpts.uri, {
      ...requestOpts,
      body: JSON.stringify(req),
      headers: {
        'Content-Type': 'application/json',
        ...requestOpts.headers
      }
    })
    .then(r => {
      if (requestOpts.text) {
        return r.text();
      }
      if (!r.ok) {
        return r.json().then(jr => {
          throw Error(jr.err)
        });
      }
      return r.json();
     })
    .then(res => {
      dispatch({
        type: name + '_SUCCESS',
        payload: {req, res}
      });
      resolve({req, res});
    })
    .catch(err => {
      dispatch({
        type: name + '_FAILURE',
        payload: {req, err: err.message}
      });
      reject({err, req});
      return null;
    })
  });
}

function CRUDOps({
  itemName, apiName, collectionName,
  collectionItemName}) {
  return {
    create: reduxXHR(`CREATE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'post'
    }),
    update: reduxXHR(`UPDATE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'put'
    }),
    read: opts => reduxXHR(`GET_${itemName}`, {
      uri: `/api/${apiName}/${opts.id}`
    })(),
    delete: reduxXHR(`DELETE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'delete'
    }),
    collection: opts => reduxXHR(`GET_${collectionItemName}`, {
      uri: `/api/${collectionName}${opts ? `?q=${JSON.stringify(opts)}` : ''}`
    })(),
  }
}

const {
  create: createDevice,
  read: getDevice,
  delete: deleteDevices,
  collection: getDevices
} = CRUDOps({
  itemName: 'DEVICE',
  apiName: 'device',
  collectionName: 'devices',
  collectionItemName: 'DEVICES'
});

const {
  create: createSession,
  read: getSession,
  delete: deleteSession
} = CRUDOps({
  itemName: 'SESSION',
  apiName: 'session'
});

const {
  create: createUser,
  read: getUser,
  delete: deleteUser,
  collection: getUsers,
  update: updateUser
} = CRUDOps({
  itemName: 'USER',
  apiName: 'user',
  collectionName: 'users',
  collectionItemName: 'USERS'
});

const {
  create: createRobot,
  read: getRobot,
  delete: deleteRobot,
  collection: getRobots
} = CRUDOps({
  itemName: 'ROBOT',
  apiName: 'robot',
  collectionName: 'robots',
  collectionItemName: 'ROBOTS'
});

const {
  create: createProgram,
  read: getProgram,
  delete: deleteProgram,
  collection: getPrograms
} = CRUDOps({
  itemName: 'PROGRAM',
  apiName: 'program',
  collectionName: 'programs',
  collectionItemName: 'PROGRAMS'
});

const getHeartbeats = reduxXHR('GET_HEARTBEATS', {
  uri: '/api/device/state/get',
  method: 'post'
});

const saveProgramData = reduxXHR('SAVE_PROGRAM_DATA', {
  uri: '/api/program-data',
  method: 'put'
});

const getMe = reduxXHR('GET_ME', {
  uri: '/api/me'
});

const signup = req => dispatch => reduxXHR('SIGNUP', {
  uri: '/api/signup',
  method: 'post'
})(req)(dispatch).then(resp => {
  console.log(resp.res);
  document.cookie = `${quipySessKey}=${resp.res.sessUuid}; path=/`;
  return resp;
});

const registerDevice = reduxXHR('REGISTER_DEVICE', {
  uri: '/api/device/register',
  method: 'post'
});

const registerDeviceCode = reduxXHR('REGISTER_DEVICE_CODE', {
  uri: '/api/device/code-register',
  method: 'post'
});

const login = req => dispatch => reduxXHR('LOGIN', {
  uri: '/api/login',
  method: 'post'
})(req)(dispatch).then(resp => {
  console.log(resp.res);
  document.cookie = `${quipySessKey}=${resp.res.sessUuid}; path=/`;
  return resp;
});

const getHomePrograms = reduxXHR('GET_HOME_PROGRAMS', {
  uri: '/api/program/categories/Home'
});

const postDeviceState = reduxXHR('POST_DEVICE_STATE', {
  uri: '/api/device/state',
  method: 'post'
});

const postRobotState = reduxXHR('POST_ROBOT_STATE', {
  uri: '/api/robots/state',
  method: 'post'
});

const saveBugReport = reduxXHR('POST_ROBOT_STATE', {
  uri: '/api/bug-report',
  method: 'post'
});

const getDeviceLogs = req => dispatch => reduxXHR('GET_DEVICE_LOGS', {
    uri: `/api/device/logs/${req.uuid}`,
  })()(dispatch);

const getDeviceFrameMetadata = req => dispatch => reduxXHR('GET_DEVICE_CAM_METADATA', {
    uri: `/api/device-cam/${req.uuid}`,
  })()(dispatch);

const getDeviceLogContent = reduxXHR('GET_DEVICE_LOG_CONTENT', {
    uri: `/api/device/log/content`,
    method: 'post',
    headers: {
      Accept: 'text/plain'
    },
    text: true
  });

const postRobotAction = reduxXHR('POST_ROBOT_ACTION', {
  uri: '/api/program-ide/runActionN',
  method: 'post'
});

const postStartReset = reduxXHR('POST_START_RESET', {
  uri: '/api/start-password-reset',
  method: 'post'
});

const postPasswordReset = reduxXHR('POST_PASSWORD_RESET', {
  uri: '/api/password-reset',
  method: 'post'
});

const postProgramIDERun = reduxXHR('POST_ROBOT_ACTION', {
  uri: '/api/program-ide/run',
  method: 'post'
});

let interval;
const refreshRobotState = opts => dispatch => {
  let pending = true;
  if (interval) {
    clearInterval(interval);
  }
  interval = setInterval(() => {
    if (!pending) {
      pending = true;
      getDevices(opts)(dispatch).then(() => {
        pending = false;
      }).catch(() => {
        pending = false;
      });
    }
  }, 8000);
  return getDevices(opts)(dispatch).then(() => {
    pending = false;
  }).catch(() => {
    pending = false;
  });
}

export {
  createDevice, getDevice, deleteDevices, getDevices,
  createProgram, getProgram, deleteProgram, getPrograms,
  createSession, getSession, deleteSession,
  createUser, getUser, deleteUser, getUsers, updateUser,
  createRobot, getRobot, deleteRobot, getRobots,
  getHeartbeats, getMe, saveProgramData, signup,
  getHomePrograms, registerDevice, postDeviceState,
  login, postRobotState, refreshRobotState,
  getDeviceLogs, getDeviceLogContent,
  getDeviceFrameMetadata, postRobotAction,
  saveBugReport, registerDeviceCode,
  postProgramIDERun, postStartReset, postPasswordReset
};
