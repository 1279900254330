function isLoadingReducer(baseActionType, loadingSuffix = '') {
  return (state, action) => {
    const newState = {...state};
    if (action.type === `${baseActionType}_START`) {
      newState[`isLoading${loadingSuffix}`] = true;
    }
    if (action.type === `${baseActionType}_SUCCESS`) {
      newState[`isLoading${loadingSuffix}`] = false;
    }
    if (action.type === `${baseActionType}_FAILURE`) {
      newState[`isLoading${loadingSuffix}`] = false;
    }
    return newState;
  }
}

export default isLoadingReducer;
