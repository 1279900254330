import {Component} from 'react';

export default class Play extends Component {
  render() {
    return (
      <svg width="25" height="25">
        <polygon points="2,0 25,12.5 2,25"
          fill={this.props.color || "#eee"} />
      </svg>
    )
  }
}
