import {Component} from 'react';
import {connect} from 'react-redux';
import Header from '../Header.js';
import { Textarea } from "baseui/textarea";
import { FileUploader } from "baseui/file-uploader";
import { Button, KIND, SIZE, SHAPE } from "baseui/button";
import {saveBugReport} from '../../actions/xhr-actions.js';
import {uploadMiscFile} from '../../actions/upload-action.js';

var dataURLToBlob = function(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

function downsizeImage(file) {
  console.log('An image has been loaded');

  // Load the image
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (readerEvent) {
      var img = new Image();
      const width = 400;
      img.onload = function () {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d"),
            oc = document.createElement('canvas'),
            octx = oc.getContext('2d');

        canvas.width = width; // destination canvas size
        canvas.height = canvas.width * img.height / img.width;

        var cur = {
          width: Math.floor(img.width * 0.5),
          height: Math.floor(img.height * 0.5)
        }

        oc.width = cur.width;
        oc.height = cur.height;

        octx.drawImage(img, 0, 0, cur.width, cur.height);

        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
        }

        ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
        resolve(
          dataURLToBlob(canvas.toDataURL('image/png'))
        )
      };
      img.src = readerEvent.target.result;
    }
    reader.readAsDataURL(file);
  });
}

class BugReport extends Component {
  constructor() {
    super();
    this.state = {
      bugReport: {
        images: []
      },
      bugReportDescription: ''
    }
  }
  render() {
    if (this.state.thankyou) {
      return (
        <div style={{
          marginBottom: '240px'
        }}>
          <Header />
          <div style={{
            position: 'relative',
            padding: '4px 48px'
          }}>
            <h1>{'Thank you, report recieved!'}</h1>
            <p>{'We will act on this report asap. Returning you to the homepage...'}</p>
          </div>
        </div>
      )
    }
    return (
      <div style={{
        marginBottom: '240px'
      }}>
        <Header />
        <div style={{
          position: 'relative',
          padding: '4px 48px'
        }}>
          <h1>{'Bug Reporter'}</h1>
          <p>{'Thank you for submitting a bug, your feedback makes ORobot ' +
            'better, and we will act on this report as soon as possible.' +
            '  Please include screenshots and step by step directions to ' +
            'reproduce as necessary'}</p>
          <Textarea placeholder={'Bug Report'}
            value={this.state.bugReportDescription}
            onChange={e => {
              this.setState({
                bugReportDescription: e.target.value
              });
            }} />
          {this.state.bugReport.images.map((iSrc, i) => {
            return (
              <span style={{display: 'inline-block', margin: '0 0 12px 0'}}>
                <div>
                  <img style={{
                    width: '150px'
                  }} src={iSrc} />
                </div>
                <Button
                  onClick={() => {
                    this.setState({
                      bugReport: {
                        ...this.state.bugReport,
                        images: [
                          ...this.state.bugReport.images.slice(0, i),
                          ...this.state.bugReport.images.slice(i+1, this.state.bugReport.images.length)
                        ]
                      }
                    })
                  }}
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  >
                  {'Delete'}
                </Button>
              </span>

            );
          })}
          <FileUploader type="file"
            id="file" name="file"
            accept="image/*"
            onDrop={e => {
              downsizeImage(e[0])
              .then(imageBlob => {
                imageBlob.filename = "bugimage.png";
                this.props.uploadMiscFile({
                  file: imageBlob,
                  body: {}
                }).then(json => {
                  this.setState({
                    bugReport: {
                      ...this.state.bugReport,
                      images: [
                        ...this.state.bugReport.images,
                        json.file.url
                      ]
                    }
                  });
                });
              })

            }}/>
          <Button onClick={() => {
            this.props.saveBugReport({
              ...this.state.bugReport,
              description: this.state.bugReportDescription
            }).then(() => {
              this.setState({
                thankyou: true
              });
              setTimeout(() => {
                window.location = '/o/home';
              }, 2000);
            });
          }}>{'Submit'}</Button>
        </div>
      </div>
    )
  }
}

export default connect(s => s, {uploadMiscFile, saveBugReport})(BugReport);
