import {Component} from 'react';
import Header from '../Header.js';
import ProgramStore from './ProgramStore.js';
import RobotList from './RobotList.js';
import {
  getRobots, refreshRobotState
} from '../../actions/xhr-actions';
import {connect} from 'react-redux';

class RobotsBrowser extends Component {
  constructor() {
    super();
    this.state = {
      openTab: 'store',
      robots: [{
        program: {
          name: '2 Wheel Car'
        }
      }],
    };
  }
  componentDidMount() {
    this.props.getRobots();
  }
  renderRobotsList() {
    return <RobotList robots={this.props.programStore.myRobots} />;
  }
  renderMobileLayout() {
    return (
      <div>
        <Header />
        <div style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}>
          <div style={{
            position: 'relative',
            display: 'inline-block',
            flexGrow: '1'
          }}>
            {this.state.openTab === 'store' ?
              <ProgramStore /> : null}
          </div>
          <div style={{
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'top'
          }}>
            {this.renderRobotsList()}
          </div>

        </div>
      </div>
    );
  }
  render() {
    if (this.props.windowInfo.width < 700) {
      return this.renderMobileLayout();
    }
    return (
      <div>
        <Header />
        <div style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}>
          <div style={{
            width: 200,
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'top'
          }}>
            {this.renderRobotsList()}
          </div>
          <div style={{
            position: 'relative',
            display: 'inline-block',
            width: 400,
            flexGrow: '1'
          }}>
            {this.state.openTab === 'store' ?
              <ProgramStore /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => s, {
  getRobots, refreshRobotState
})(RobotsBrowser);
