import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import HomePage from './App.js'
import DevicesPage from './components/OnlineDeviceControls/Devices.js'
import ProgramEditorPage from './components/ProgramEditor/ProgramEditor.js'
import RobotsBrowserPage from './components/RobotBrowser/RobotsBrowser.js'
import RobotOperationPage from './components/RobotOperator/RobotOperationPage.js';
import ProgramViewPage from './components/ProgramViewPage.js';
import Signup from './components/auth/Signup.js';
import Login from './components/auth/Login.js';
import Reset from './components/auth/Reset.js';
import Forgot from './components/auth/Forgot.js';
import Shop from './components/Shop.js';
import Profile from './components/Profile.js';
import Logout from './components/auth/Logout.js';
import UserSection from './components/UserSection.js';
import UserProfile from './components/UserProfile.js';
import ProgramIDE from './components/ProgramIDE/ProgramIDE.js';
import DeviceStickerRoute from './components/DeviceStickerRoute.js';
import BugReport from './components/BugReport/BugReport.js';
import RobotBuilder from './components/RobotBuilder/RobotBuilder.js';
import './css/base.css';

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Route path="/" component={HomePage} exact />
        <Route path="/auth">
          <Route path="/auth/signup" exact component={Signup} />
          <Route path="/auth/login" exact component={Login} />
          <Route path="/auth/reset/:token" exact component={Reset} />
          <Route path="/auth/forgot" exact component={Forgot} />
          <Route path="/auth/logout" exact component={Logout} />
        </Route>
        <Route path="/o">
          <UserSection>
            <Switch>
              <Route path="/o/devices" exact component={DevicesPage} />
              <Route path="/o/program/create" component={ProgramEditorPage} />
              <Route path="/o/program-ide" component={ProgramIDE} />
              <Route path="/o/program-editor/:uuid" component={ProgramEditorPage} />
              <Route path="/o/program-view/:uuid" component={ProgramViewPage} />
              <Route path="/o/robots" component={RobotsBrowserPage} />
              <Route path="/o/robot-operation/:id" component={RobotOperationPage} />
              <Route path="/o/shop" component={Shop} />
              <Route path="/o/profile" component={Profile} />
              <Route path="/o/users/:uuid" component={UserProfile} />
              <Route path="/o/device-sticker/:uuid" component={DeviceStickerRoute} />
              <Route path="/o/bug-report" component={BugReport} />
              <Route path="/o/robot-builder" component={RobotBuilder} />
              <Redirect to="/o/robots" />
            </Switch>
          </UserSection>
        </Route>
      </Router>
    )
  }
}
