import {Component} from 'react';
import Header from '../Header.js';
import ReactCodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';
import ProgramResourceSlots from '../RobotOperator/ProgramResourceSlots.js';
import {init, loadModel, getMyDiagram, getSerializedState} from '../gojs-pid-graph.js';
import { StatefulSlider } from "baseui/slider";
import { Tabs, Tab } from "baseui/tabs";
import {createProgram,
  saveProgramData,
  getProgram} from '../../actions/xhr-actions';
import {uploadFile} from '../../actions/upload-action';
import {connect} from 'react-redux';
import { Button, KIND, SIZE, SHAPE } from "baseui/button";
import { Input } from "baseui/input";
import { FileUploader } from "baseui/file-uploader";
import { Textarea } from "baseui/textarea";
import {StlViewer} from "react-stl-viewer";
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import { StatefulSelect } from "baseui/select";
import {Spinner} from 'baseui/spinner';

//const API_URL = 'https://robots-gateway.uc.r.appspot.com';
//const API_URL = 'http://localhost:8080';
const API_URL = ''
const defaultGraph = `{ "class": "GraphLinksModel",
"copiesArrays": true,
"copiesArrayObjects": true,
"linkFromPortIdProperty": "fromPort",
"linkToPortIdProperty": "toPort",
"nodeDataArray": [
{"name":"Feedback", "leftArray":[], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[ {"portId":"top0", "portColor":"#66d6d1"} ], "bottomArray":[], "key":-5, "loc":"80.27232480293705 378.0044271676925"},
{"name":"Derivative", "leftArray":[ {"portId":"left0", "portColor":"#d6effc"} ], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[], "bottomArray":[], "key":-6, "loc":"213 322"},
{"name":"Integral", "leftArray":[ {"portId":"left0", "portColor":"#66d6d1"} ], "rightArray":[ {"portId":"right0", "portColor":"#66d6d1"} ], "topArray":[], "bottomArray":[], "key":-8, "loc":"212 213"},
{"name":"Proportional", "leftArray":[ {"portId":"left0", "portColor":"#eaeef8"} ], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[], "bottomArray":[], "key":-9, "loc":"208 117"},
{"name":"Sigma", "leftArray":[ {"portId":"left0", "portColor":"#fae3d7"} ], "rightArray":[ {"portId":"right0", "portColor":"#fadfe5"},{"portId":"right1", "portColor":"#fadfe5"},{"portId":"right2", "portColor":"#6cafdb"} ], "topArray":[], "bottomArray":[ {"portId":"bottom0", "portColor":"#6cafdb"} ], "key":-10, "loc":"80 212.8214015765036"},
{"name":"Set Point", "leftArray":[], "rightArray":[ {"portId":"right0", "portColor":"#ebe3fc"} ], "topArray":[], "bottomArray":[], "key":-11, "loc":"-30.863837598531482 213.09372637944068"},
{"name":"Sigma", "leftArray":[ {"portId":"left0", "portColor":"#ebe3fc"},{"portId":"left1", "portColor":"#fae3d7"},{"portId":"left2", "portColor":"#66d6d1"} ], "rightArray":[ {"portId":"right0", "portColor":"#fadfe5"} ], "topArray":[], "bottomArray":[ {"portId":"bottom0", "portColor":"#66d6d1"} ], "key":-7, "loc":"334.3051506316812 213.36788683727463"},
{"name":"Output", "leftArray":[ {"portId":"left0", "portColor":"#fae3d7"} ], "rightArray":[], "topArray":[], "bottomArray":[], "key":-12, "loc":"468.19997840406 214.23172443580603"}
],
"linkDataArray": [
{"from":-10, "to":-9, "fromPort":"right0", "toPort":"left0", "points":[118,202.8214015765036,132,202.8214015765036,162,202.8214015765036,162,117,160,117,170,117]},
{"from":-10, "to":-8, "fromPort":"right1", "toPort":"left0", "points":[118,212.8214015765036,136,212.8214015765036,164,212.8214015765036,164,213,160,213,174,213]},
{"from":-10, "to":-6, "fromPort":"right2", "toPort":"left0", "points":[118,222.8214015765036,128,222.8214015765036,160.5,222.8214015765036,160.5,322,161,322,175,322]},
{"from":-5, "to":-10, "fromPort":"top0", "toPort":"bottom0", "points":[80.27232480293705,340.0044271676925,80.27232480293705,326.0044271676925,80.27232480293705,291.41291437209804,80,291.41291437209804,80,260.8214015765036,80,250.8214015765036]},
{"from":-11, "to":-10, "fromPort":"right0", "toPort":"left0", "points":[7.136162401468518,213.09372637944068,21.136162401468518,213.09372637944068,21.136162401468518,212.8214015765036,21.136162401468518,212.8214015765036,32,212.8214015765036,42,212.8214015765036]},
{"from":-9, "to":-7, "fromPort":"right0", "toPort":"left0", "points":[246,117,256,117,285.15257531584064,117,285.15257531584064,203.36788683727463,282.3051506316812,203.36788683727463,296.3051506316812,203.36788683727463]},
{"from":-8, "to":-7, "fromPort":"right0", "toPort":"left1", "points":[250,213,260,213,283.15257531584064,213,283.15257531584064,213.36788683727463,274.3051506316812,213.36788683727463,296.3051506316812,213.36788683727463]},
{"from":-6, "to":-7, "fromPort":"right0", "toPort":"left2", "points":[251,322,265,322,268,322,268,223.36788683727463,286.3051506316812,223.36788683727463,296.3051506316812,223.36788683727463]},
{"from":-7, "to":-5, "fromPort":"bottom0", "toPort":"right0", "points":[334.3051506316812,251.36788683727463,334.3051506316812,265.3678868372746,334.3051506316812,378.0044271676925,233.28873771730912,378.0044271676925,132.27232480293705,378.0044271676925,118.27232480293705,378.0044271676925]},
{"from":-7, "to":-12, "fromPort":"right0", "toPort":"left0", "points":[372.3051506316812,213.36788683727463,382.3051506316812,213.36788683727463,415.2525645178706,213.36788683727463,415.2525645178706,214.23172443580603,416.19997840406,214.23172443580603,430.19997840406,214.23172443580603]}
]}`;

var dataURLToBlob = function(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

function downsizeImage(file) {
  console.log('An image has been loaded');

  // Load the image
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (readerEvent) {
      var img = new Image();
      const width = 400;
      img.onload = function () {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d"),
            oc = document.createElement('canvas'),
            octx = oc.getContext('2d');

        canvas.width = width; // destination canvas size
        canvas.height = canvas.width * img.height / img.width;

        var cur = {
          width: Math.floor(img.width * 0.5),
          height: Math.floor(img.height * 0.5)
        }

        oc.width = cur.width;
        oc.height = cur.height;

        octx.drawImage(img, 0, 0, cur.width, cur.height);

        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
        }

        ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
        resolve(
          dataURLToBlob(canvas.toDataURL('image/png'))
        )
      };
      img.src = readerEvent.target.result;
    }
    reader.readAsDataURL(file);
  });
}

class ProgramEditor extends Component {
  constructor() {
    super();
    this.state = {
      panelName: 'none',
      activeKey: '1',
      program: {
        name: '',
        description: '',
        resources: [],
        images: [],
        files: [],
        config:
`{
   actions: [{
    label: 'Feed Now',
    function: 'feed'
  }]
}`,
        code:
`send(resources[0], {
  type: 'command-in',
  data: 'fastright'
});
`,
        pidGraphs: defaultGraph
      }
    };
  }
  componentDidMount() {
    if (this.props.match.params.uuid) {
      this.props.getProgram({
        id: this.props.match.params.uuid
      })
        .then(({res}) => {
          this.setState({
            program: res
          });
        });
    } else {
      this.props.createProgram()
        .then((resp) => {
          this.setState({
            program: {
              ...this.state.program,
              uuid: resp.res.uuid
            }
          });
        });
    }
  }
  initPIDGraph() {
    init();
    loadModel(JSON.parse(this.state.program.pidGraphs || defaultGraph));
    const myDiagram = getMyDiagram();
    myDiagram.addDiagramListener("ChangedSelection", (e) => {
      e.subject.each((sel) => {
        console.log('got selection', sel.data);
        this.setState({
          panelName: sel.data.name
        });
      });
    });
  }
  renderConfigTab() {
    return (
      <div>
        <label>{'Configs'}</label>
        <ReactCodeEditor
          value={this.state.program.config}
          onValueChange={config => this.setState({ program: {
            ...this.state.program,
            config } })}
          highlight={config => highlight(config, languages.json)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            height: '100%'
          }}
          />
        </div>
    );
  }
  renderCodeTab() {
    return (
      <div>
        <label>{'Code'}</label>
        <ReactCodeEditor
          value={this.state.program.code}
          onValueChange={code => this.setState({ program: {...this.state.program, code } })}
          highlight={code => highlight(code, languages.js)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            height: '100%'
          }}
          />
        </div>
    );
  }
  renderMotionPlanning() {
    return (
      <div>
        <Button>
          {'Add Pose'}
        </Button>
        <Card
          title="Pose 1">
          <StyledBody>
            <StatefulSelect
              options={[{
                label: 'up',
                id: 0
              }, {
                label: 'down',
                id: 1
              }]}
              placeholder="Select Pose" />
            <StatefulSelect
              options={[{
                label: 'time 1',
                id: 0
              }, {
                label: 'time 2',
                id: 1
              }]}
              placeholder="Select Condition" />
          </StyledBody>
          <StyledAction>
          </StyledAction>
        </Card>
        <Card
          title="Pose 2">
          <StyledBody>
            <StatefulSelect
              options={[{
                label: 'up',
                id: 0
              }, {
                label: 'down',
                id: 1
              }]}
              placeholder="Select Pose" />
            <StatefulSelect
              options={[{
                label: 'time 1',
                id: 0
              }, {
                label: 'time 2',
                id: 1
              }]}
              placeholder="Select Condition" />
          </StyledBody>
          <StyledAction>
          </StyledAction>
        </Card>
      </div>
    );
  }
  renderPIDTab() {
    return (
      <div style={{
        position: 'relative'
      }}>
        <label>{'PID Diagram'}</label>
        <div style={{
          height: '400px',
          width: '60%',
          position: 'relative'
        }}>
          <div style={{
            width: '100%',
            height: '100%'
          }} id="myDiagramDiv" />
        </div>
        <div style={{
          width: '40%',
          bottom: '0',
          top: '0',
          right: '0',
          position: 'absolute',
          backgroundColor: '#ccc',
          display: 'inline-block',
          verticalAlign: 'top'
        }}>
          <div style={{
            margin: '24px'
          }}>
            {this.state.panelName}
            <StatefulSlider />
          </div>
        </div>
      </div>
    );
  }
  renderEditor() {
    return (
      <div>
        <div style={{
          width: '100%'
        }}>
          <iframe name="hiddenFrame" width="0" height="0" border="0" style={{display: 'none'}}></iframe>
          <form
            onSubmit={(e) => e.preventDefault()}
            target="hiddenFrame">
            <h4>Info</h4>
            <Input type="text" placeholder="name"
              value={this.state.program.name}
              onChange={e => {
                this.setState({
                  program: {
                    ...this.state.program,
                    name: e.target.value
                  }
                });
              }}
              id="name" name="name" />
            <div style={{
              marginTop: '12px'
            }}>
            <Textarea
              //value={ value}
              //onChange={e => setValue(e.target.value)}
              placeholder="Summary"
              value={this.state.program.description}
              onChange={e => {
                this.setState({
                  program: {
                    ...this.state.program,
                    description: e.target.value
                  }
                });
              }}
              clearOnEscape
            />
            </div>
            <div style={{
              margin: '12px 0'
            }}>
              <Button onClick={() => {
                this.props.saveProgramData({
                  ...this.state.program,
                  pidGraphs: getSerializedState() || defaultGraph,
                  thumb: this.state.program.images[0]
                });
              }}>{'Submit'}</Button>
            </div>
          </form>
          <h4>Images</h4>
          {this.state.program.images.map((iSrc, i) => {
            return (
              <span style={{display: 'inline-block', margin: '0 0 12px 0'}}>
                <div>
                  <img style={{
                    width: '150px'
                  }} src={iSrc} />
                </div>
                <Button
                  onClick={() => {
                    this.setState({
                      program: {
                        ...this.state.program,
                        images: [
                          ...this.state.program.images.slice(0, i),
                          ...this.state.program.images.slice(i+1, this.state.program.images.length)
                        ]
                      }
                    })
                  }}
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  >
                  {'Delete'}
                </Button>
              </span>

            );
          })}
          <form target="hiddenFrame">
            <Input type="hidden" id="programUuid" name="programUuid" value={this.state.program.uuid} />
            <FileUploader type="file"
              id="file" name="file"
              onDrop={e => {
                downsizeImage(e[0])
                .then(imageBlob => {

                  this.props.uploadFile({
                    file: imageBlob,
                    body: {
                      programUuid: this.state.program.uuid
                    }
                  }).then(json => {
                    this.setState({
                      program: {
                        ...this.state.program,
                        images: [
                          ...this.state.program.images,
                          json.file.url
                        ]
                      }
                    });
                  });
                })

              }}/>
          </form>
          <h4>Files</h4>
          <form
            target="hiddenFrame">
            <div style={{
              display: 'flex',
              maxWidth: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              maxWidth: '100%'}}>
              {this.state.program.files.map((fileSrc, i) => {
                return (
                  <span style={{
                    display: 'inline-block',
                    margin: '0 0 24px 0'
                  }}>
                    <StlViewer
                      url={fileSrc}
                      style={{
                        width: '240px',
                        height: '240px'
                      }}
                      modelProps={{
                        color: '#2222ff'
                      }}
                      orbitControls={true}
                    />
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({
                            program: {
                              ...this.state.program,
                              files: [
                                ...this.state.program.files.slice(0, i),
                                ...this.state.program.files.slice(i+1, this.state.program.files.length)
                              ]
                            }
                          })
                        }}
                        kind={KIND.secondary}
                        size={SIZE.mini}
                        shape={SHAPE.pill}>
                        {'Delete'}
                      </Button>
                    </div>
                  </span>
                );
              })}
            </div>
            <FileUploader type="file"
              id="file" name="file"
              onDrop={e => {
                this.props.uploadFile({
                  file: e[0],
                  body: {
                    programUuid: this.state.program.uuid
                  }
                }).then(json => {
                  this.setState({
                    program: {
                      ...this.state.program,
                      files: [
                        ...this.state.program.files,
                        json.file.url
                      ]
                    }
                  });
                });
              }}/>
          </form>
          <ProgramResourceSlots
            program={this.state.program}
            onResourceRemove={(i) => {
              this.setState({
                program: {
                  ...this.state.program,
                  resources: [
                    ...this.state.program.resources.slice(0, i),
                    ...this.state.program.resources.slice(i+1, this.state.program.resources.length)
                  ]
                }
              })
            }}
            onResourceAdd={(opts) => {
              this.setState({
                program: {
                  ...this.state.program,
                  resources: this.state.program.resources
                    .concat(opts)
                }
              })
            }}/>
          <Tabs
            onChange={({ activeKey }) => {
              this.setState({activeKey}, () => {
                if (activeKey === "2") {
                  this.initPIDGraph()
                }
              });
            }}
            activeKey={this.state.activeKey}
          >
            <Tab title="Configs">{this.renderConfigTab()}</Tab>
            <Tab title="Code">{this.renderCodeTab()}</Tab>
            <Tab title="PID Graphs">{this.renderPIDTab()}</Tab>
            <Tab title="Action Planning">{this.renderMotionPlanning()}</Tab>
          </Tabs>
        </div>
        <Button onClick={() => {
          this.props.saveProgramData({
            ...this.state.program,
            config: JSON.stringify(
              JSON.parse(this.state.program.config.replace(/\r?\n|\r/g, ''))
            ),
            pidGraphs: getSerializedState() || defaultGraph,
            thumb: this.state.program.images[0]
          });
        }}>{'Submit'}</Button>
      </div>
    )
  }
  render() {
    return (
      <div style={{
        marginBottom: '240px'
      }}>
        <Header />
        <div style={{
          position: 'relative',
          padding: '4px 48px'
        }}>
          {this.props.programEditor.isLoadingProgram ?
            <Spinner /> :
            this.renderEditor()}
        </div>
      </div>
    );
  }
}

const ConnectedProgramEditor = connect(s => s, {
  createProgram,
  saveProgramData,
  uploadFile,
  getProgram
})(ProgramEditor);

export default ConnectedProgramEditor;
