import {Component} from 'react';

export default class Plus extends Component {
  render() {
    return (
      <svg width="25" height="25">
        <rect x="0" y="10" height="5" width="25" fill="#eee" />
        <rect x="10" y="0" height="25" width="5" fill="#eee"/>
      </svg>
    )
  }
}
