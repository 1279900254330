import React, {Component} from 'react';
import Header from '../Header.js';
import {connect} from 'react-redux';
import {deleteSession} from '../../actions/xhr-actions.js';
import '../../css/auth.css';

const quipySessKey = '_osess';

class Logout extends Component {
  componentDidMount() {
    document.cookie = `${quipySessKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    window.location = '/auth/login';
  }
  render() {
    return (
      <div>
        <Header />
      </div>
    );
  }
}

export default connect(null, {
  deleteSession
})(Logout);
