import React, {Component} from 'react';
import Header from '../Header.js';
import '../../css/auth.css';
import {
  signup
} from '../../actions/xhr-actions';
import {connect} from 'react-redux';

class Reset extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      name: ''
    };
  }
  render() {
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <form onSubmit={e => e.preventDefault()}>
            <div className="form-item">
              <input type="text"
                onChange={e => this.setState({
                  email: e.target.value
                })}
                value={this.state.email}
                placeholder="email">
              </input>
            </div>
            <div className="form-item">
              <input type="text"
                onChange={e => this.setState({
                  name: e.target.value
                })}
                value={this.state.name}
                placeholder="name">
              </input>
            </div>
            <div className="form-item">
              <input type="password"
                onChange={e => this.setState({
                  password: e.target.value
                })}
                value={this.state.password}
                placeholder="password">
              </input>
            </div>
            <div className="form-item">
              <button onClick={() => {
                const {
                  password,
                  email,
                  name
                } = this.state;
                this.props.signup({
                  password,
                  email,
                  name
                }).then(() => {
                  setTimeout(() => {
                    window.location = '/o/robots';
                  }, 500);
                });
              }}>Signup</button>
            </div>
            <div className="form-item">
              <a href="/auth/login">Login</a>
            </div>
            <div className="form-item">
              <a href="/auth/forgot">Forgot Password</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  signup
})(Reset);
