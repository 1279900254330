import calcHexColorFromUuid from '../uuid-to-color.js';
import {createRef, Component} from 'react';

class DeviceSticker extends Component {
  render() {
    const circleSize = this.props.circleSize || 180;
    return (
      <div style={{margin: '12px'}}>
        <span style={{position: 'relative'}}>
          <svg width={circleSize} height={circleSize} style={{
            verticalAlign: 'top'
          }}>
            <circle cx={circleSize/2} cy={circleSize/2} r={circleSize/2}
              fill={`#${calcHexColorFromUuid(this.props.uuid)}`} />
          </svg>
          <span style={{
            position: 'absolute',
            lineHeight: `${circleSize}px`,
            fontSize: `${circleSize/5}px`,
            right: 0,
            left: 0
          }}>
            <div style={{
              margin: '0 auto',
              display: 'table'
            }}>
              <span style={{
                backgroundColor: 'white',
                padding: `${circleSize/45}px`,
                borderRadius: `${circleSize/20}px`
              }}>
                {this.props.uuid.slice(0, 4)}
              </span>
            </div>
          </span>

        </span>
        <div>
          <div>
            <strong style={{
              minWidth: '48px',
              marginTop: '4px',
              display: 'inline-block'
            }}>Wifi:</strong>
            {` ORobot-Setup-${this.props.uuid.slice(0, 4)}`}
          </div>
          <div>
            <strong style={{
              minWidth: '48px',
              marginTop: '4px',
              display: 'inline-block'
            }}>Pass:</strong>
            {` wifisetup`}
          </div>
          <div>
            <strong style={{
              minWidth: '48px',
              marginTop: '4px',
              display: 'inline-block'
            }}>URL:</strong>
            {` http://192.168.0.172`}
          </div>
          <div style={{
            width: '180px',
            marginTop: '4px'
          }}>
            <div style={{
              margin: '0 auto',
              display: 'table'
            }}><strong>{'orobot.io'}</strong></div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceSticker;
