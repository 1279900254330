const ideSelectProgram = req => dispatch => dispatch({
  type: 'IDE_SELECT_PROGRAM',
  payload: req
});

const openIDEWorkpanel = req => dispatch => dispatch({
  type: 'IDE_OPEN_PANEL',
  payload: req
});

const closeIDEWorkpanel = req => dispatch => dispatch({
  type: 'IDE_CLOSE_PANEL',
  payload: req
});

const attachDevDevice = req => dispatch => dispatch({
  type: 'ATTACH_DEV_DEVICE',
  payload: req
});

const unattachDevDevice = req => dispatch => dispatch({
  type: 'UNATTACH_DEV_DEVICE',
  payload: req
});


export {
  ideSelectProgram,
  closeIDEWorkpanel,
  openIDEWorkpanel,
  attachDevDevice,
  unattachDevDevice
};
