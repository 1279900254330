const WS_URL = window.location.host === 'localhost:3000' ?
  'ws:localhost:8080/orobotio' : 'wss://robots-gateway.uc.r.appspot.com/orobotio';

let socket = null;

const getSocket = () => socket;

const initSocket = req => dispatch => {
  const prm = new Promise((resolve, reject) => {
    if (!socket) {
      socket = new WebSocket(WS_URL, 'ssh-protocol');
      socket.addEventListener('message', e => {
        dispatch({
          type: 'SOCKET_MESSAGE',
          payload: {data: e.data}
        });
      });
      socket.onopen = () => {
        dispatch({
          type: 'SOCKET_INIT',
          payload: {socket}
        });
        resolve(socket);
      }
      socket.onclose = () => {
        dispatch({
          type: 'SOCKET_CLOSE',
          payload: {socket}
        });
      }
    } else if (socket.readyState === 0) {
      socket.addEventListener('open', () => {
        resolve(socket);
      });
    } else {
      resolve(socket);
    }
  });
  return prm;
};

const sendSocketMessage = req => dispatch => {
  socket.send(
    JSON.stringify(req)
  );
  return socket;
};

export {getSocket, initSocket, sendSocketMessage};
