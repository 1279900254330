import {Component} from 'react';
import Header from '../Header.js';
import Plus from '../icons/Plus.js';
import Minus from '../icons/Minus.js';
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import { Button, KIND, SIZE, SHAPE } from "baseui/button";

export default class ProgramResourceSlots extends Component {
  render() {
    return (
      <div style={{
        margin: '12px 0'
      }}>
      <div style={{
        marginBottom: '12px'
      }}>
        <Button onClick={() => {
          this.props.onResourceAdd({
            type: 'wifi-motor'
          })}}
          size={SIZE.compact}>
          {'Add Motor'}
        </Button>
        <span style={{
          marginLeft: '12px'
        }}>
          <Button onClick={() => {
            this.props.onResourceAdd({
              type: 'camera'
            })
          }}
          size={SIZE.compact}>
            {'Add Camera'}
          </Button>
        </span>
      </div>
      <div style={{
        display: 'flex'
      }}>
        {this.props.program.resources.map((pr, i) => {
          return (
            <Card
              overrides={{Root: {style: {
                width: '368px',
                margin: '48px 12px 0 0',
                cursor: 'pointer'}}}}
              onClick={this.props.onClick}
              title={`${pr.type} ${i+1}`}>
              <StyledBody>
              <Button
                onClick={() => this.props.onResourceRemove(i)}
                kind={KIND.secondary}
                size={SIZE.mini}
                shape={SHAPE.pill}>
                {'Remove Motor'}
              </Button>
              </StyledBody>
            </Card>
          );
        })}
      </div>
      </div>
    );
  }
}
