import {React, Component} from 'react';
import {connect} from 'react-redux';
import OnlineDeviceControls from '../OnlineDeviceControls/OnlineDeviceControls.js';
import {getDevice} from '../../actions/xhr-actions';
import {attachDevDevice} from '../../actions/user-actions';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";

class ConnectedOnlineDeviceControls extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
    //this.props.getDevice(this.props.deviceUuid);
  }
  render() {
    const device = this.props.devices.find(d => {
      return (
        d.uuid === this.props.deviceUuid
      );
    });
    if (device) {
      return (
          <span>
            <span style={{
              verticalAlign: 'top',
              display: 'inline-block',
              margin: '0 0 12px 12px'
            }}>
              <Button
                size={SIZE.mini}
                shape={SHAPE.pill}
                kind={KIND.secondary}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.attachDevDevice({
                    resourceN: this.props.devResources.length,
                    uuid: this.props.deviceUuid
                  });
                }}>
                {'Dev Attach'}
              </Button>
            </span>
            <div>
              <OnlineDeviceControls
                deviceUuid={this.props.deviceUuid}
                device={device} />
            </div>
          </span>
      );
    }
    return (
      <div>{this.props.deviceUuid}</div>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  getDevice, attachDevDevice
})(ConnectedOnlineDeviceControls);
