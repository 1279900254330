const defaultState = {
  me: null,
  meInput: {},
  unread: 0,
  karma: 0
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_ME_START':
    return {
      ...state,
      isLoading: true
    };
  case 'GET_ME_FAILURE':
    return {
      ...state,
      isLoading: false
    };
  case 'GET_ME_SUCCESS':
    return {
      ...state,
      isLoading: false,
      me: action.payload.res,
      meInput: action.payload.res
    };
  case 'ME_INPUT_UPDATE':
    return {
      ...state,
      meInput: {
        ...state.meInput,
        ...action.payload
      }
    };
  case 'GET_UNREAD_COUNT_SUCCESS':
    return {
      ...state,
      unread: action.payload.res
    };
  case 'GET_KARMA_SUCCESS':
    return {
      ...state,
      karma: action.payload.res
    };
  default:
    return state;
  }
}
