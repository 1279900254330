import {Component} from 'react';
import '../css/header.css';
import {connect} from 'react-redux';
import { StatefulPopover, Popover } from "baseui/popover";
import Menu from 'baseui/icon/menu';
import TriangleDown from 'baseui/icon/triangle-down';
import {userFlags} from '../helpers/user-flags.js';
import {Spinner} from 'baseui/spinner';

const FUNDING = {
  sofar: 10,
  needed: 135
}

class Header extends Component {
  constructor() {
    super();
    this.state = {};
  }
  renderMobileQuickLinks() {
    return (
      <span style={{
        marginLeft: '24px',
        color: 'white',
        verticalAlign: 'middle'
      }}>
        {this.state.menuOpen ?
          <TriangleDown size={36}
            onClick={() => {
              this.setState({
                menuOpen: false
              });
            }}/> :
          <Menu size={36}
            onClick={() => {
              this.setState({
                menuOpen: true
              });
            }}/>}
          <Popover
            isOpen={this.state.menuOpen}
            content={
              <div style={{
                lineHeight: '32px',
                margin: '0 12px'
              }}>
                <div>
                  <a href="/o/robots">
                    Home
                  </a>
                </div>
                {userFlags(this.props.me.me, 'programs.create') ?
                  <div>
                    <a href="/o/program-ide">
                      Program IDE
                    </a>
                  </div> : null}
                <div>
                  <a href="/o/devices">
                    Devices
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://orobot-io.myshopify.com/">
                    Store
                  </a>
                </div>
                <div>
                  <a target="_blank" href="https://discord.gg/j6BvdnAdct">
                    Discuss
                  </a>
                </div>
                <div>
                  <a href="/docs">
                    Docs
                  </a>
                </div>
                <div>
                  <a href="/o/bug-report">
                    Bug Report
                  </a>
                </div>
              </div>
          }>
          <span />
        </Popover>
      </span>
    );
  }
  renderQuickLinks() {
    if (this.props.windowInfo.width < 700) {
      return this.renderMobileQuickLinks();
    }
    return (
      <span>
        <span className="header-item">
          <a href="/o/robots">
            Home
          </a>
        </span>
        {userFlags(this.props.me.me, 'programs.ide') ?
          <span className="header-item">
            <a href="/o/program-ide">
              Program IDE
            </a>
          </span> : null}
        {userFlags(this.props.me.me, 'robots.builder') ?
          <span className="header-item">
            <a href="/o/robot-builder">
              Sim
            </a>
          </span> : null}
        {this.props.me.me ?
          <span className="header-item">
            <a href="/o/devices">
              Devices
            </a>
          </span> : null}
        <span className="header-item">
          <a target="_blank" href="https://orobot-io.myshopify.com/">
            Store
          </a>
        </span>
        <span className="header-item">
          <a target="_blank" href="https://discord.gg/j6BvdnAdct">
            Discuss
          </a>
        </span>
        <span className="header-item">
          <a href="/docs">
            Docs
          </a>
        </span>
        <span className="header-item">
          <a href="/o/bug-report">
            Bug Report
          </a>
        </span>
      </span>
    )
  }
  render() {
    return (
      <div style={{
        width: '100%',
        verticalAlign: 'middle',
        padding: '8px 24px',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0, 0, 45, 1)'
      }}>
        <a href="/"
          style={{
            verticalAlign: 'middle',
            position: 'relative'
          }}>
          <img src={"/logo3-thumb.png"} style={{
            height: '50px'
          }}/>
          <span style={{
            color: 'white',
            position: 'absolute',
            left: '18px',
            fontSize: '12px'
          }}>{'Alpha'}</span>
        </a>
        {this.renderQuickLinks()}
        <div style={{
          float: 'right'
        }}>
          <StatefulPopover
            content={() => (
              <div style={{
                lineHeight: '32px',
                margin: '0 12px'
              }}>
                <div>
                  <a href={`/o/profile`}>
                    Update Profile
                  </a>
                </div>
                <div>
                  <a href={`/auth/logout`} onClick={() => {
                      var gapi;
                      if (!gapi) {
                        return;
                      }
                      var auth2 = gapi.auth2.getAuthInstance();
                      auth2.signOut().then(function () {
                        console.log('User signed out.');
                      });
                    }}>
                    Logout
                  </a>
                </div>
              </div>
            )}>
            {this.props.me.me ?
              <span className="header-item" style={{
                cursor: 'pointer'
              }}>
                <a>{this.props.me.me.name || this.props.me.me.email}</a>
              </span> :
              this.props.me.isLoading ?
                <Spinner /> :
                <span>
                  <span className="header-item" style={{
                    cursor: 'pointer'
                  }}>
                    <a href="/auth/signup">{'Signup'}</a>
                  </span>
                  <span className="header-item" style={{
                    cursor: 'pointer'
                  }}>
                    <a href="/auth/login">{'Login'}</a>
                  </span>
                </span> }
          </StatefulPopover>
        </div>
      </div>
    );
  }
}

const ConnectedHeader = connect(s => s)(Header);
export default ConnectedHeader;
