import {React, Component} from 'react';
import { Button } from "baseui/button";
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import { StatefulSelect } from "baseui/select";

export default class PosesEditor extends Component {
  render() {
    return (
      <div>
        <Button>
          {'Add Pose'}
        </Button>
        <Card
          title="Pose 1">
          <StyledBody>
            <StatefulSelect
              options={[{
                label: 'up',
                id: 0
              }, {
                label: 'down',
                id: 1
              }]}
              placeholder="Select Pose" />
            <StatefulSelect
              options={[{
                label: 'time 1',
                id: 0
              }, {
                label: 'time 2',
                id: 1
              }]}
              placeholder="Select Condition" />
          </StyledBody>
          <StyledAction>
          </StyledAction>
        </Card>
        <Card
          title="Pose 2">
          <StyledBody>
            <StatefulSelect
              options={[{
                label: 'up',
                id: 0
              }, {
                label: 'down',
                id: 1
              }]}
              placeholder="Select Pose" />
            <StatefulSelect
              options={[{
                label: 'time 1',
                id: 0
              }, {
                label: 'time 2',
                id: 1
              }]}
              placeholder="Select Condition" />
          </StyledBody>
          <StyledAction>
          </StyledAction>
        </Card>
      </div>
    );
  }
}
