import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  getMe
} from '../actions/xhr-actions';

class UserSection extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getMe();
  }
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default connect(null, {
  getMe
})(UserSection);
