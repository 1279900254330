import {Component} from 'react';
import {
  refreshRobotState,
  getHeartbeats,
  registerDeviceCode
} from '../../actions/xhr-actions';
import AppEventBus from '../../event-bus.js';
import {Terminal} from 'xterm';
import Header from '../Header.js';
import '../../xterm.css';
import OnlineDeviceControls from './OnlineDeviceControls.js';
import {getProgram} from '../../actions/xhr-actions';
import {connect} from 'react-redux';
import { Accordion, Panel } from "baseui/accordion";
import moment from 'moment';
import { Avatar } from "baseui/avatar";
import { Button, SIZE, SHAPE, KIND } from "baseui/button";
import DeviceOnlineStatusIndicator from '../DeviceOnlineStatusIndicator';
import {Card, StyledBody, StyledAction} from 'baseui/card';
import {Spinner} from 'baseui/spinner';
import calcHexColorFromUuid from '../../uuid-to-color.js';
import DeviceSticker from '../DeviceSticker.js';
import DeviceMetadata from './DeviceMetadata.js';

class Devices extends Component {
  constructor() {
    super();
    this.state = {
      openCards: {}
    };
  }
  componentWillMount() {
    this.props.refreshRobotState();
    //throw new Error('test');
  }
  renderProfileIcon(d) {
    const circleSize = 40;
    return (
      <DeviceSticker uuid={d.uuid}
        circleSize={circleSize} />
    );
  }
  renderOnline(d) {
    if (!d || !d.state) {
      return null;
    }
    console.log(Date.now(), moment(d.state.updatedAt).valueOf());
    if (Date.now() - moment(d.state.updatedAt).valueOf() < 100000) {
      return (
        <span style={{marginLeft: '4px'}}>
          <svg height="19" width="19">
            <circle cx="9" cy="9" r="9" fill="#39B200" />
          </svg>
          <strong style={{marginLeft: '8px'}}>{'Online'}</strong>
        </span>)
    }
    return (
      <span style={{marginLeft: '4px'}}>
        <strong>{'Last Online: '}</strong>
        {moment(d.state.updatedAt).fromNow()}
      </span>
    );
  }
  render() {
    return (
      <div>
        <Header />
        <div style={{margin: '12px'}}>
          <Button onClick={o => {
            this.setState({openRegisterForm: true});
          }}>
            {'Register Device'}
          </Button>
        </div>
        {this.state.openRegisterForm ? (
          <div>
            <form onSubmit={(e) => {
              e.preventDefault()
              this.props.registerDeviceCode({
                code: this.state.deviceUuidInput
              }).then(() => {
                this.setState({openRegisterForm: false});
              });
            }}>
              <div style={{marginBottom: '12px'}}>
                <input type="text" placeholder="device uuid"
                  value={this.state.deviceUuidInput}
                  onChange={e => {
                    this.setState({
                      deviceUuidInput: e.target.value
                    });
                  }}/>
              </div>
              <button type="submit">{'Register'}</button>
            </form>
          </div>
        ) : null}
        <div style={{
          margin: '0 12px'
        }}>
          {!this.props.programStore.hasFirstLoadedDevices ?
            <Spinner /> : null}
          {this.props.programIDE.devices.length === 0 &&
            this.props.programStore.hasFirstLoadedDevices ?
            <div>
              <img style={{
                width: '50%',
                display: 'table',
                filter: 'grayscale(100%)',
                margin: '0 auto'
              }} src={"/Robot1.png"} />
              <div style={{
                display: 'table',
                margin: '0 auto'
              }}>
                <span>{'You have no devices, purchase and register one.'}</span>
              </div>
            </div> : null}
          {this.props.programIDE.devices.map(d => {
            if (!this.state.openCards[d.uuid]) {
              return (
                <Card key={d.uuid} style={{
                  display: 'inline-block',
                  margin: '0 24px 24px 0',
                  minWidth: '300px',
                  maxWidth: '780px'
                }}>
                  <StyledBody>
                  <div>
                    <DeviceMetadata device={d} />
                  </div>
                  </StyledBody>
                  <StyledAction>
                    <Button size={SIZE.mini} shape={SHAPE.pill}
                      kind={KIND.secondary}
                      onClick={() => {
                        this.setState({
                          openCards: {
                            ...this.state.openCards,
                            [d.uuid]: true
                          }
                        });
                      }}>
                      {'Open'}
                    </Button>
                  </StyledAction>
                </Card>
              );
            }
            return (
              <Card key={d.uuid} style={{
                marginBottom: '24px',
                maxWidth: '780px'
              }}>
                <StyledBody>
                  <OnlineDeviceControls device={d} />
                </StyledBody>
                <StyledAction>
                  <Button size={SIZE.mini} shape={SHAPE.pill}
                    kind={KIND.secondary}
                    onClick={() => {
                      this.setState({
                        openCards: {
                          ...this.state.openCards,
                          [d.uuid]: false
                        }
                      });
                    }}>
                    {'Close'}
                  </Button>
                </StyledAction>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

const ConnectedDevices = connect(s => s, {
  refreshRobotState, getHeartbeats, registerDeviceCode
})(Devices);

export default ConnectedDevices;
