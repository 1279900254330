
const DEFAULT_STATE = {
  session: {
    loggedIn: true
  },
  user: {
    name: 'Alex Jansen',
    email: 'xanderjanz@gmail.com',
    uuid: ''
  }
};

export default function (state, action) {
  switch(action.type) {
  case 'USER_GET_RESPONSE':
    return {
      ...action.response.payload
    };
  case 'USER_GET_REQUEST':
    return {
      ...action.response.payload
    }
  default:
    return state || DEFAULT_STATE;
  }
}
