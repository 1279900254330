import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers';
import thunkMiddleware from 'redux-thunk';

export default function createAppStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    reducers,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )
  );
}
