import React, {createRef, Component} from 'react';
import QRCode from 'qrcode-svg';
import {
  deleteDevices,
  postDeviceState
} from '../../actions/xhr-actions';
import AppEventBus from '../../event-bus.js';
import '../../css/devices.css';

import { Button, SIZE, SHAPE } from "baseui/button";
import {connect} from 'react-redux';
import { Input } from "baseui/input";
import {initSocket, sendSocketMessage, getSocket} from '../../actions/socket-actions';
import ButtonPanel from './ButtonPanel.js';
import Script from './Script.js';
import SSH from './SSH.js';
import DeviceMetadata from './DeviceMetadata.js';
import CameraUI from './CameraUI.js';
import LogsUI from './LogsUI.js';
import { Notification, KIND } from "baseui/notification";
import {userFlags} from '../../helpers/user-flags.js';
import {Spinner} from 'baseui/spinner';
import calcHexColorFromUuid from '../../uuid-to-color.js';
import WifiSelector from './WifiSelector.js';

class OnlineDeviceControls extends Component {
  constructor() {
    super();
    this.state = {
      terminalOpen: false,
      stepperUI: false,
      openTab: 'terminal',
      message: '',
      isLoading: true
    };
    this.canvasRef = createRef();
    this.handleSSHPopup = this.handleSSHPopup.bind(this);
    this.handleControllerPopup = this.handleControllerPopup.bind(this);
    this.handleScriptPopup = this.handleScriptPopup.bind(this);
    this.initSocket = this.initSocket.bind(this);
    this.handleCameraPopup = this.handleCameraPopup.bind(this);
    this.handleLogsPopup = this.handleLogsPopup.bind(this);
  }
  componentDidMount() {
    this.initSocket();
    const socket = getSocket();
    socket.addEventListener('message', (ev) => {
      try {
        const msg = JSON.parse(ev.data);
        if ((msg.type === 'connection-rejected' ||
          msg.type === 'force-disconnect') &&
          msg.deviceUuid === this.props.device.uuid) {
          this.setState({
            connectionRejected: true,
            isLoading: false
          });
          if (msg.ackId) {
            socket.send(JSON.stringify({
              type: 'message-ack',
              ackId: msg.ackId,
              deviceUuid: this.props.device.uuid
            }));
          }
        }
        if (msg.type === 'connection-approved' &&
          msg.deviceUuid === this.props.device.uuid) {
          this.setState({
            isLoading: false,
            connectionRejected: false
          });
          if (msg.ackId) {
            socket.send(JSON.stringify({
              type: 'message-ack',
              ackId: msg.ackId,
              deviceUuid: this.props.device.uuid
            }));
          }
        }
      } catch (e) {

      }
    })
  }
  initSocket() {
    this.props.initSocket().then((socket) => {
      this.props.sendSocketMessage({
        type: 'identify-connection',
        userUuid: 'd08d7603-156e-4a8d-a7f2-ff267d6f2de1'
      });
      this.props.sendSocketMessage({
        type: 'connect-to-device',
        userUuid: 'd08d7603-156e-4a8d-a7f2-ff267d6f2de1',
        deviceUuid: this.props.device.uuid
      });
      this.setState({socket});
    });
  }
  handleSSHPopup() {
    if (!this.state.terminalOpen) {
      this.setState({
        openTab: 'terminal'
      });
    }
  }

  handleControllerPopup() {
    if (!this.state.controllerOpen) {
      this.setState({
        openTab: 'controller'
      });
    }
  }
  handleScriptPopup() {
    if (!this.state.scriptOpen) {
      this.setState({
        openTab: 'script'
      });
    }
  }
  handleCameraPopup() {
    if (!this.state.cameraOpen) {
      this.setState({
        openTab: 'camera'
      });
    }
  }
  handleLogsPopup() {
    if (!this.state.cameraOpen) {
      this.setState({
        openTab: 'logs'
      });
    }
  }
  getTerminalId() {
    return `terminal-${this.props.device.uuid}`;
  }
  supportsMotor() {
    return this.props.device.state &&
      this.props.device.state.type === 'wifi-motor';
  }
  supportsCamera() {
    return this.props.device.state &&
      this.props.device.state.type === 'wifi-camera';
  }
  renderActionInput() {
    if (!this.state.actionInput) {
      return null;
    }
    return (
      <div>
        <Input value={this.state.actionInputValue}
          onChange={e => this.setState({actionInputValue: e.target.value})} />
        <Button size={SIZE.mini} shape={SHAPE.pill}
          onClick={() => {
            this.setState({
              actionInput: null,
              actionInputValue: '',
              actionInputPrefix: ''
            });
            this.props.sendSocketMessage({
              type: this.state.actionInput,
              deviceUuid: this.props.device.uuid,
              data: `${this.state.actionInputPrefix}:${this.state.actionInputValue}`
            });
          }}>
          {'Go'}
        </Button>
      </div>
    )
  }
  renderButtons() {
    return (
      <div style={{
        padding: '12px'
      }}>
        <span className={`panel-action ${this.state.openTab === 'terminal' ? 'aactive' : null}`}
          onClick={this.handleSSHPopup}>{"SSH"}</span>
        {this.supportsMotor() ?
          <span className={`panel-action ${this.state.openTab === 'controller' ? 'aactive' : null}`}
            onClick={this.handleControllerPopup}>{"Controller"}</span> : null}
        <span className={`panel-action ${this.state.openTab === 'script' ? 'aactive' : null}`}
          onClick={this.handleScriptPopup}>{"Script"}</span>
        {this.supportsCamera() ?
          <span className={`panel-action ${this.state.openTab === 'camera' ? 'aactive' : null}`}
            onClick={this.handleCameraPopup}>{"Camera"}</span> : null}
        <span className={`panel-action ${this.state.openTab === 'logs' ? 'aactive' : null}`}
          onClick={this.handleLogsPopup}>{"Logs"}</span>
        <span className={`panel-action ${this.state.openTab === 'wifi' ? 'aactive' : null}`}
          onClick={() => this.setState({
            openTab: 'wifi'
          })}>{"Wifi"}</span>
      </div>
    );
  }
  renderRejectedConnection() {
    return (
      <Notification kind={KIND.negative}>
        {"This device is already controlled, and can only have 1 controller. "}
        <span style={{
          textDecoration: 'underline',
          cursor: 'pointer'
        }} onClick={() => {
          this.props.sendSocketMessage({
            type: 'force-disconnect',
            deviceUuid: this.props.device.uuid
          });
          this.setState({
            isLoading: true
          });
          setTimeout(() => {
            this.initSocket();
          }, 1000);
        }}>
          {'Force connect.'}
        </span>
      </Notification>
    )
  }
  renderedConnectedActions() {
    const circleSize = 40;
    return (
      <span>
      <div>
        <span id={`printicon-${this.props.device.uuid}`} style={{
          opacity: '0',
          position: 'absolute',
          pointerEvents: 'none'
        }}>
          <canvas ref={this.canvasRef}>
            <svg width={circleSize} height={circleSize} ref={this.svgRef}>
              <circle cx={circleSize/2} cy={circleSize/2} r={circleSize/2}
                fill={`#${calcHexColorFromUuid(this.props.device.uuid)}`} />
              <rect width={30} height={20} x={5} y={10} rx={3} fill={"white"} />
              <text x={5} y={25} color={"black"}>
                {this.props.device.uuid.slice(0, 4)}
              </text>
            </svg>
          </canvas>
        </span>
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <a href={`/o/device-sticker/${this.props.device.uuid}`}
            target="_blank"
          >
            <Button
              size={SIZE.mini}
              shape={SHAPE.pill}>
              {'Download Sticker'}
            </Button>
          </a>
        </span>
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={(e) => {
              e.preventDefault();
              this.props.sendSocketMessage({
                type: 'networkmode',
                deviceUuid: this.props.device.uuid,
                data: 'ap'
              });
            }}>
            {'Wifi Switch Mode'}
          </Button>
        </span>
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={(e) => {
              e.preventDefault();
              this.props.sendSocketMessage({
                type: 'command-in',
                deviceUuid: this.props.device.uuid,
                data: `update`
              });
            }}>
            {'Update Firmware'}
          </Button>
        </span>
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={(e) => {
              e.preventDefault();
              this.props.sendSocketMessage({
                type: 'command-in',
                deviceUuid: this.props.device.uuid,
                data: `reboot`
              });
            }}>
            {'Reboot'}
          </Button>
        </span>
        {userFlags(this.props.me.me, 'devices.networkmode') ?
          <span>
          <span style={{
            marginLeft: '12px',
            marginTop: '6px',
            display: 'inline-block'
          }}>
            <Button
              size={SIZE.mini}
              shape={SHAPE.pill}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  actionInput: 'networkmode',
                  actionInputPrefix: 'dev'
                });
              }}>
              {'Dev Network'}
            </Button>
          </span>
          <span style={{
            marginLeft: '12px',
            marginTop: '6px',
            display: 'inline-block'
          }}>
            <Button
              size={SIZE.mini}
              shape={SHAPE.pill}
              onClick={(e) => {
                e.preventDefault();
                this.props.sendSocketMessage({
                  type: 'networkmode',
                  deviceUuid: this.props.device.uuid,
                  data: 'client'
                });
              }}>
              {'Prod Network'}
            </Button>
          </span>
        </span> : null}
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={(e) => {
              e.preventDefault();
              this.props.deleteDevices({
                uuid: this.props.device.uuid
              }).then(() => {
                window.location.reload();
              });
            }}>
            {'Delete'}
          </Button>
        </span>
      </div>
      {this.renderActionInput()}
        <div style={{
          height: '36px',
          position: 'relative'
        }}>
          {this.renderButtons()}
        </div>
        <SSH
          display={this.state.openTab === 'terminal'}
          socket={this.state.socket}
          device={this.props.device} />
        <ButtonPanel
          display={this.state.openTab === 'controller'}
          socket={this.state.socket}
          device={this.props.device} />
        {this.state.openTab === 'script' ?
          <Script
            socket={this.state.socket}
            device={this.props.device} /> : null}
        {this.state.openTab === 'camera' ?
          <CameraUI device={this.props.device} /> : null}
        {this.state.openTab === 'logs' ?
          <LogsUI device={this.props.device} /> : null}
        {this.state.openTab === 'wifi' ?
          <WifiSelector device={this.props.device} /> : null}
        </span>
      );
  }
  render() {
    return (
      <div>
        <DeviceMetadata
          sendSocketMessage={this.props.sendSocketMessage}
          device={this.props.device} />
        {this.state.isLoading ?
          <Spinner /> : null}
        {this.state.connectionRejected ?
          this.renderRejectedConnection() : null}
        {!this.state.connectionRejected &&
          !this.state.isLoading ?
          this.renderedConnectedActions() : null}
      </div>
    );
  }
}

export default connect(s => s, {
  deleteDevices, postDeviceState, initSocket, sendSocketMessage
})(OnlineDeviceControls)
