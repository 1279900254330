import {Component} from 'react';
import Play from '../icons/Play.js';
import Stop from '../icons/Stop.js';
import { highlight, languages } from 'prismjs/components/prism-core';
import ReactCodeEditor from 'react-simple-code-editor';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import {sendSocketMessage} from '../../actions/socket-actions';
import {connect} from 'react-redux';

class Script extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code:
`function async dance() {
  await goForward();
  await wait(1000);
  await stop();
  await turnRight();
  await turnRight();
  await goForward();
  await wait(1000);
  await stop();
  await turnRight();
  await turnRight();
}
`
    };
  }
  render() {
    return (
      <div style={{
        height: '240px',
        position: 'relative',
      }}>
        <div style={{
          display: 'inline-block',
          width: '60%',
          height: '100%'
        }}>
          <ReactCodeEditor
            value={this.state.code}
            onValueChange={code => this.setState({ code })}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              height: '100%'
            }}
            />
        </div>
        <div style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: '40%',
          height: '100%'
        }}>
          <div style={{height: '35%', padding: '0 12px'}}>
            <div>
              <svg width="36" height="36">
                <circle cx="18" cy="18" r="18" fill="#c00" />
              </svg>
              <span style={{
                lineHeight:"36px",
                verticalAlign: 'top',
                marginLeft: '12px'
              }}>
                {'Running'}
              </span>
            </div>
            <div>
              <span style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: '48px'
              }}
                onClick={() => {
                  this.props.sendSocketMessage({
                    type: 'command-in',
                    deviceUuid: this.props.device.uuid,
                    data: {
                      code: this.state.code
                    }
                  });
                }}>
                <Play />
              </span>
              <span style={{
                display: 'inline-block',
                marginLeft: '24px',
                cursor: 'pointer'
              }}>
                <Stop />
              </span>
            </div>
          </div>

          <div style={{
            backgroundColor: '#eee',
            width: '100%',
            height: '65%'
          }}>
            <div>{'> Running'}</div>
            <div>{'> ping { “ts”: 300134 }'}</div>
            <div>{'> ping { “ts”: 300143 }'}</div>
            <div>{'> ping { “ts”: 300234 }'}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  sendSocketMessage
})(Script)
