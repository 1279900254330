import moment from 'moment';
import {Component} from 'react';

const onlineTime = 16000;
const wrongTime = 24000;

class DeviceOnlineStatusIndicator extends Component {
  render() {
    if (!(this.props.deviceState.updatedAt)) {
      return null;
    }
    if (Date.now() - moment(this.props.deviceState.updatedAt).valueOf() < onlineTime) {
      return (
        <span>
          <svg height="19" width="19" style={{verticalAlign: 'text-bottom'}}>
            <circle cx="9" cy="9" r="9" fill="#39B200" />
          </svg>
          {this.props.statusText ?
            <span style={{marginLeft: '8px'}}>{'Online'}</span> : null}
        </span>)
    }
    if (Date.now() - moment(this.props.deviceState.updatedAt).valueOf() < wrongTime) {
      return (
        <span>
          <svg height="19" width="19" style={{verticalAlign: 'text-bottom'}}>
            <circle cx="9" cy="9" r="9" fill="#e8a200" />
          </svg>
          {this.props.statusText ?
            <span style={{marginLeft: '8px'}}>{'Somethings Wrong'}</span> : null}
        </span>)
    }
    if (!this.props.timeOffline) {
      return (
        <span>
          <svg height="19" width="19" style={{verticalAlign: 'text-bottom'}}>
            <circle cx="9" cy="9" r="9" fill="#CCC" />
          </svg>
          {this.props.statusText ?
            <span style={{marginLeft: '8px'}}>{'Offline'}</span> : null}
        </span>)
    }
    return (
      <span>
        <span>{'Last Online: '}</span>
        {this.props.statusText ?
          moment(this.props.deviceState.updatedAt).fromNow() : null}
      </span>
    );
  }
}

DeviceOnlineStatusIndicator.defaultProps = {
  deviceState: {},
  timeOffline: true,
  statusText: true
};

export default DeviceOnlineStatusIndicator;
