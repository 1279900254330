import { Terminal, IDisposable, ITerminalAddon } from 'xterm';

export class PTYWSAttachAddon {
  _socket;
  _bidirectional;
  _disposables = [];

  constructor(socket, options) {
    this._socket = socket;
    this.deviceUuid = options.deviceUuid;
    // always set binary type to arraybuffer, we do not handle blobs
    this._socket.binaryType = 'arraybuffer';
    this._bidirectional = (options && options.bidirectional === false) ? false : true;
  }

  activate(terminal) {
    this._disposables.push(
      addSocketListener(this._socket, 'message', ev => {
        try {
          const msg = JSON.parse(ev.data);
          if (msg.type === 'pty-out' && msg.deviceUuid === this.deviceUuid) {
            terminal.write(msg.data);
            this._socket.send(JSON.stringify({
              type: 'message-ack',
              ackId: msg.ackId,
              deviceUuid: msg.deviceUuid
            }));
          }
        } catch (e) {

        }
      })
    );

    if (this._bidirectional) {
      this._disposables.push(terminal.onData(data => this._sendData(data)));
    }

    this._disposables.push(addSocketListener(this._socket, 'close', () => this.dispose()));
    this._disposables.push(addSocketListener(this._socket, 'error', () => this.dispose()));
  }

  dispose() {
    this._disposables.forEach(d => d.dispose());
  }

  _sendData(data) {
    // TODO: do something better than just swallowing
    // the data if the socket is not in a working condition
    if (this._socket.readyState !== 1) {
      return;
    }
    this._socket.send(JSON.stringify({
      type: 'pty-in',
      data: data,
      deviceUuid: this.deviceUuid
    }));
  }
}

function addSocketListener(socket, type, handler) {
  socket.addEventListener(type, handler);
  return {
    dispose: () => {
      if (!handler) {
        // Already disposed
        return;
      }
      socket.removeEventListener(type, handler);
    }
  };
}
