import {Component} from 'react';
import {
  getDeviceLogs,
  getDeviceLogContent
} from '../../actions/xhr-actions';
import {connect} from 'react-redux';
import ChevronLeft from 'baseui/icon/chevron-left';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";

class LogsUI extends Component {
  constructor() {
    super();
    this.state = {res: []};
  }
  componentDidMount() {
    this.props.getDeviceLogs({
      uuid: this.props.device.uuid
    }).then(({res}) => {
      this.setState({
        res
      });
    });
  }
  renderLogContents() {
    return (
      <div>
        <Button
          size={SIZE.mini}
          shape={SHAPE.circle}
          kind={KIND.secondary} onClick={() => {
          this.setState({logContents: null})
        }}  >
          <ChevronLeft size={24}  />
        </Button>
        <div style={{
          whiteSpace: 'pre-line',
          overflowY: 'scroll',
          height: '300px'
        }}>
          {this.state.logContents}
        </div>
      </div>
    );
  }
  render() {
    if (this.state.logContents) {
      return this.renderLogContents();
    }
    return (
      <div style={{
        maxHeight: 400
      }}>
        {this.state.res.map(item => {
          return (
            <div style={{marginBottom: '6px'}}>
              <Button
                size={SIZE.mini}
                shape={SHAPE.pill}
                kind={KIND.secondary}
                onClick={() => {
                  this.props.getDeviceLogContent({
                    name: item.name
                  }).then(({res}) => {
                    this.setState({
                      logContents: res
                    })
                  });
                }}>
                {`${item.name.replace(`${this.props.device.uuid}/`, '')} ${
                  (item.size / 1000).toFixed(1)
                }KB`}
              </Button>
            </div>
          );
        })}
      </div>
    );
  }
}
export default connect(null, {
  getDeviceLogs, getDeviceLogContent})(LogsUI);
