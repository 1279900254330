import {Component} from 'react';
import { Navigation } from "baseui/side-navigation";
import { Button } from "baseui/button";
import {
  HeadingSmall
} from 'baseui/typography';
import {connect} from 'react-redux';
import {Spinner} from 'baseui/spinner';

function isRobotsResourcesOnline(robot, props) {
  return robot.state && !!(robot.state.resources.reduce((acc, resource) => {
    return acc && props.programStore.devices[resource] &&
      !!(Date.now() - props.programStore.devices[resource].state.updatedAt < 8000);
  }, true));
}

class RobotList extends Component {
  renderRobotList() {
    if (this.props.robots.length > 0 ||
      this.props.programStore.isLoadingRobots) {
      return null;
    }
    return (
      <div style={{
        margin: '0 6px'
      }}>
        <img style={{
          width: 'auto',
          maxWidth: '50%',
          display: 'table',
          filter: 'grayscale(100%)',
          margin: '0 auto'
        }} src={"/Robot2.png"} />
        <div style={{
          display: 'table',
          margin: '0 auto'
        }}>
          <span>{this.props.me.me ?
            'No Robots installed. Select one to install on the right. 👉' :
            <span>
              {'You don\'t have an account. '}
              <a href="/o/auth/signup">
                {'Create one.'}
              </a>
            </span>}
          </span>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <div style={{
          marginLeft: '24px'
        }}>
          <HeadingSmall>{'My Robots'}</HeadingSmall>
        </div>
        {this.props.programStore.isLoadingRobots ?
          <div style={{
            display: 'table',
            margin: '0 auto'
          }}>
            <Spinner />
          </div> : null}
        {this.renderRobotList()}
        <Navigation
          items={this.props.robots.map(r => (
            {
              title: (
                <div>
                  <span style={{}}>
                    {r.name}
                  </span>
                    {r.online ? (
                      <div>
                        <svg height="19" width="19">
                          <circle cx="9" cy="9" r="9" fill="#39B200" />
                        </svg>
                        <strong style={{marginLeft: '8px'}}>{'Online'}</strong>
                      </div>
                    ) : (
                      <div>
                        <svg height="19" width="19">
                          <circle cx="9" cy="9" r="9" fill="#e0e0e0" />
                        </svg>
                        <strong style={{marginLeft: '8px'}}>{'Offline'}</strong>
                      </div>
                    )}
                </div>
              ),
              itemId: `/o/robot-operation/${r.uuid}`
            }
          ))} />
        </div>
      );
  }
}

export default connect(s => s, {})(RobotList)
