import {Component} from 'react';

export default class Minus extends Component {
  render() {
    return (
      <svg width="25" height="25">
        <rect x="0" y="10" height="5" width="25" fill="#eee" />
      </svg>
    )
  }
}
