import DeviceSticker from './DeviceSticker.js';
import {createRef, Component} from 'react';

class DeviceStickerRoute extends Component {
  render() {
    const circleSize = this.props.circleSize || 180;
    return (
      <DeviceSticker uuid={this.props.match.params.uuid} />
    );
  }
}

export default DeviceStickerRoute;
