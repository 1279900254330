import {Component} from 'react';
import Header from '../Header.js';
import RobotResourceSlots from './RobotResourceSlots.js';
import {connect} from 'react-redux';
import {getRobot, deleteRobot,
  postRobotState, postRobotAction} from '../../actions/xhr-actions.js';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import CameraUI from '../OnlineDeviceControls/CameraUI.js';
import {StlViewer} from "react-stl-viewer";

class RobotOperationPanel extends Component {
  constructor() {
    super();
    this.state = {
      openTab: 'store',
      robot: {
        Program: {
          files: []
        }
      }
    };
  }
  componentDidMount() {
    this.props.getRobot({
      id: this.props.match.params.id
    }).then(res => {
      this.setState({robot: res.res});
    });
  }
  getProgram() {
    return this.state.robot.Program;
  }
  getConfigJSON() {
    try {
      return JSON.parse(this.state.robot.Program.config);
    } catch (e) {
      return {};
    }
  }
  renderDeviceList() {
    return (
      <div>
        <div>{'Attach devices:'}</div>
        <RobotResourceSlots
          key={JSON.stringify(this.state.robot)}
          attachDeviceToResource={(device, resources) => {
            this.props.postRobotState({
              robotUuid: this.state.robot.uuid,
              resources
            });
          }}
          robotResource={((this.state.robot.state || {}).resources || [])}
          program={this.getProgram()} />
      </div>
    );
  }
  renderSummary() {
    return (
      <div style={{
        padding: '24px 0'
      }}>
        {this.getProgram().summary}
      </div>
    );
  }
  renderActions() {
    const actions = this.getConfigJSON().actions;
    return (
      <div>
        {actions ? actions.map((a, actionN) => {
          return (
            <div style={{
              display: 'inline-block',
              border: '1px solid #2222ff',
              borderRadius: '4px',
              color: '#2222ff',
              margin: '0 12px 12px 0',
              padding: '2px 12px',
              cursor: 'pointer'
            }}  key={actionN} onClick={() => {
              this.props.postRobotAction({
                program: this.state.robot.Program,
                resources: this.state.robot.state.resources,
                actionN: actionN
              });
            }}>
              {a.label}
            </div>
          )
        }) : null}
      </div>
    );
  }
  renderConfigs() {
    return (
      <div>
      </div>
    );
  }
  renderResources() {
    return (
      <div>
        {this.state.robot.Program.resources &&
          this.state.robot.Program.resources.map((r, i) => {
          if (r.type === 'camera') {
            return (
              <div>
                <CameraUI device={{
                  uuid: ((this.state.robot.state || {}).resources || [])[i]
                }} />
              </div>
            )
          }
        })}
      </div>
    );
  }
  renderProgramLink() {
    return (
      <div style={{marginBottom: '16px'}}>
        <span>
          {'Program type: '}
        </span>
        <a
          href={`/o/program-view/${this.state.robot.Program.uuid}`}>
          {this.state.robot.Program.name}
        </a>
        <img
          style={{width: 80, height: 80, marginLeft: '12px'}}
          src={this.state.robot.Program.thumb} />
      </div>
    )
  }
  renderProgramFiles() {
    return (
      <div style={{
        marginBottom: '16px'
      }}>
      <div>{'Parts to Download and Print:'}</div>
      {this.getProgram().files.map(fileSrc => {
        return (
          <span style={{
            display: 'inline-block'
          }}>
            <StlViewer
              url={fileSrc}
              style={{
                width: '240px',
                height: '240px'
              }}
              modelProps={{
                color: '#2222ff'
              }}
              orbitControls={true}
            />
            <div style={{
              margin: '0 24px'
            }}>
              <a
                href={fileSrc}
                download>
                <Button
                  kind={KIND.secondary}
                  size={SIZE.compact}
                  shape={SHAPE.pill}>{'Download'}</Button>
              </a>
            </div>
          </span>
        );
      })}
      </div>
    )
  }
  renderRobotOperationPanel() {
    return (
      <div>
        {this.renderProgramLink()}
        {this.renderProgramFiles()}
        {this.renderDeviceList()}
        {this.renderSummary()}
        {this.renderActions()}
        {this.renderResources()}
        {this.renderConfigs()}
      </div>
    );
  }
  render() {
    return (
      <div>
        <Header />
        <div style={{
          position: 'relative',
          display: 'inline-block',
          margin: '0 24px'
        }}>
          <h2>{this.state.robot.Program.name}</h2>
          <Button
            size={SIZE.compact}
            shape={SHAPE.pill}
            onClick={() => {
              this.props.deleteRobot({
                uuid: this.props.match.params.id
              });
            }}>
            {'Delete'}
          </Button>
          {this.renderRobotOperationPanel()}
        </div>
      </div>
    );
  }
}

export default connect(null, {
  getRobot, deleteRobot, postRobotState,
  postRobotAction
})(RobotOperationPanel);
