import isLoading from './helpers/is-loading';

const DEFAULT_STATE = {};

function programEditor(oldState = DEFAULT_STATE, action) {
  let state = {...oldState};
  state = isLoading('GET_PROGRAM', 'Program')(state, action);

  return state;
}

export default programEditor;
