import React, {Component} from 'react';
import Header from '../Header.js';
import '../../css/auth.css';
import {postStartReset} from '../../actions/xhr-actions.js';
import {connect} from 'react-redux';

class Forgot extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <form onSubmit={e => {
            e.preventDefault();
            this.props.postStartReset({
              email: this.state.email
            });
          }}>
            <div className="form-item">
              <input type="text"
                placeholder="email"
                value={this.state.email}
                onChange={e => {
                  this.setState({
                    email: e.target.value
                  });
                }}>
              </input>
            </div>
            <div className="form-item">
              <button>Request Reset Email</button>
            </div>
            <div className="form-item">
              <a href="/auth/login">Login</a>
            </div>
            <div className="form-item">
              <a href="/auth/signup">Signup</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default connect(s => s, {
  postStartReset
})(Forgot);
