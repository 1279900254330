import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes.js';
import reportWebVitals from './reportWebVitals';
import {w3cwebsocket as W3CWebSocket} from 'websocket';
import AppEventBus from './event-bus.js';
import createStore from './store.js';
import {Provider} from 'react-redux';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';
import {StatefulInput} from 'baseui/input';
import sessionCookieInit from './session-cookie';
import AuthLoader from './components/AuthLoader.js';
import windowInfoMonitor from './window-info-monitor.js';
import StackdriverErrorReporter from 'stackdriver-errors-js';

const errorHandler = new StackdriverErrorReporter();
errorHandler.start({
    targetUrl: '/error-report'
});

const engine = new Styletron();

/*var client = new W3CWebSocket('wss://robots-gateway.uc.r.appspot.com/', 'echo-protocol');

client.onerror = function() {
    console.log('Connection Error');
};

client.onopen = function() {
    console.log('WebSocket Client Connected');

};

client.onclose = function() {
    console.log('echo-protocol Client Closed');
};

client.onmessage = function(e) {
    if (typeof e.data === 'string') {
        console.log("Received: '" + e.data + "'");
        AppEventBus.emit('ws', null, e);
    }
};*/

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <Provider store={store}>
          <AuthLoader>
            <Routes />
          </AuthLoader>
        </Provider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

sessionCookieInit(store);
windowInfoMonitor(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
