import {React, Component} from 'react';
import {connect} from 'react-redux';
import {closeIDEWorkpanel} from '../../actions/user-actions';
import {StlViewer} from "react-stl-viewer";
import ConnectedWorkFieldPanelContainer from './WorkPanelContainer';
import CodeEditor from './CodeEditor';
import ConfigEditor from './ConfigEditor';
import PIDGraphEditor from './PIDGraphEditor';
import PosesEditor from './PosesEditor';
import ConnectedOnlineDeviceControls from './ConnectedOnlineDeviceControls.js';;

class WorkField extends Component {
  render() {
    return (
      <div>
        {Object.keys(this.props.openPanels).map(panel => {
          const panelKey = panel.split('$$');
          switch (panelKey[0]) {
          case 'programfactor':
            switch (panelKey[1]) {
              case 'code':
                return (
                  <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                    <CodeEditor
                      key="%%codeeditor%%"
                      code={this.props.selectedProgram.code}/>
                  </ConnectedWorkFieldPanelContainer>
                );
              case 'configs':
                return (
                  <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                    <ConfigEditor
                      config={this.props.selectedProgram.config}/>
                  </ConnectedWorkFieldPanelContainer>
                );
              case 'poses':
                return (
                  <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                    <ConfigEditor
                      config={this.props.selectedProgram.config}/>
                  </ConnectedWorkFieldPanelContainer>
                );
              case 'pidgraph':
                return (
                  <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                    <PIDGraphEditor
                      pidGraphs={this.props.selectedProgram.pidGraphs}/>
                  </ConnectedWorkFieldPanelContainer>
                );
            }
            return (
              <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                {'oops'}
              </ConnectedWorkFieldPanelContainer>
            );
          case 'device':
            return (
              <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                <ConnectedOnlineDeviceControls deviceUuid={panelKey[1]} />
              </ConnectedWorkFieldPanelContainer>
            );
          case 'photo':
            return (
              <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                <img
                  style={{
                    width: '100%'
                  }}
                  src={panelKey[1]} />
              </ConnectedWorkFieldPanelContainer>
            );
          case 'file':
            return (
              <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
                <StlViewer
                  url={panelKey[1]}
                  style={{
                    width: '240px',
                    height: '240px'
                  }}
                  modelProps={{
                    color: '#2222ff'
                  }}
                  orbitControls={true}
                />
              </ConnectedWorkFieldPanelContainer>
            );
          case 'resource':
            return (
              <ConnectedWorkFieldPanelContainer panel={panel} key={panelKey}>
              </ConnectedWorkFieldPanelContainer>
            );
          }
        })}
      </div>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  closeIDEWorkpanel
})(WorkField);
