import isLoadingReducer from './helpers/is-loading';

const defaultState = {
  programs: [],
  selectedProgram: null,
  devices: [],
  openPanels: {},
  devResources: []
};

export default function (oldState, action) {
  if (!oldState) {
    return defaultState;
  }
  let state = {...oldState};
  state = isLoadingReducer('GET_PROGRAM', 'Program')(state, action);
  state = isLoadingReducer('GET_PROGRAMS', 'Programs')(state, action);
  switch (action.type) {
  case 'GET_DEVICES_SUCCESS':
    return {
      ...state,
      devices: action.payload.res
    }

  case 'GET_PROGRAMS_SUCCESS':
    return {
      ...state,
      programs: action.payload.res
    }
  case 'IDE_SELECT_PROGRAM':
    return {
      ...state,
      selectedProgramOption: action.payload
    }
  case 'GET_PROGRAM_SUCCESS':
    return {
      ...state,
      selectedProgram: action.payload.res
    }
  case 'ATTACH_DEV_DEVICE':
    const newResourceList = [...state.devResources];
    newResourceList[action.payload.resourceN] = action.payload.uuid;
    return {
      ...state,
      devResources: newResourceList
    };
  case 'UNATTACH_DEV_DEVICE':
    const unattachResourceN = state.devResources.reduce(
      (a, dUuid, i) => {
        if (dUuid === action.payload.uuid) {
          return i;
        }
        return a;
      }, null);
    const newUnResourceList = [
      ...state.devResources.slice(0, unattachResourceN),
      ...state.devResources.slice(unattachResourceN + 1)];
    return {
      ...state,
      devResources: newUnResourceList
    };
  case 'IDE_OPEN_PANEL':
    return {
      ...state,
      openPanels: {
        ...state.openPanels,
        [action.payload.openPanel]: action.payload
      }
    }
  case 'GET_DEVICE_SUCCESS':
    return {
      ...state,
      devices: {
        [action.payload.req.deviceUuid]: action.payload.res
      }
    }
  case 'IDE_CLOSE_PANEL':
    const newPanels = {...state.openPanels};
    delete newPanels[action.payload.closePanel];
    return {
      ...state,
      openPanels: newPanels
    }
  default:
    return state;
  }
}
