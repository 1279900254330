import {React, Component} from 'react';
import {connect} from 'react-redux';
import { Select } from "baseui/select";
import {ideSelectProgram} from '../../actions/user-actions.js';
import {getProgram} from '../../actions/xhr-actions';
import moment from 'moment';

const defaultRobotPfp = (pfpUuid = "default") => {
  const defaultImages = ["/Robot1-thumb.png", "/Robot2-thumb.png"];
  return defaultImages[Math.floor(((pfpUuid.charCodeAt(0)/100) % 1) * defaultImages.length)];
}

class ProgramSelector extends Component {
  render() {
    return (
      <Select
        onChange={({value}) => {
          this.props.ideSelectProgram(value);
          this.props.getProgram({
            id: value[0].id
          });
        }}
        value={this.props.selectedProgramOption}
        getOptionLabel={({option: {program}}) => {
          return (
            <span>
              <img style={{
                width: '48px', height: '48px',
                objectFit: 'cover'
              }} src={program.thumb || defaultRobotPfp(program.uuid)} />
              <span style={{
                display: 'inline-block',
                margin: '0 12px'
              }}>
                <div>
                  {program.name}
                </div>
                <div>
                  {moment(program.updatedAt).fromNow()}
                </div>
              </span>
            </span>
          );
        }}
        onSelectResetsInput={true}
        getValueLabel={({option: {program}}) => {
          return (
            <span>
              <img style={{
                width: '48px', height: '48px'
              }} src={program.thumb || defaultRobotPfp(program.uuid)} />
              <span style={{
                display: 'inline-block',
                margin: '0 12px'
              }}>
                <div>
                  {program.name}
                </div>
                <div>
                  {moment(program.updatedAt).fromNow()}
                </div>
              </span>
            </span>
          );
        }}
        options={this.props.programs.map(d => ({
          program: d,
          name: d.name,
          id: d.uuid
        }))}
        labelKey="name"
        valueKey="id"/>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  ideSelectProgram, getProgram
})(ProgramSelector);
