import React, {Component} from 'react';
import Header from './Header.js';
import {deleteSession, updateUser} from '../actions/xhr-actions.js';
import {uploadProfilePhoto} from '../actions/upload-action.js';
import '../css/auth.css';
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { FileUploader } from "baseui/file-uploader";
import { Textarea } from "baseui/textarea";import { Avatar } from "baseui/avatar";
import {connect} from 'react-redux';
import Plus from 'baseui/icon/plus';

//const API_URL = 'https://robots-gateway.uc.r.appspot.com';
const API_URL = 'http://localhost:3000';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      editOpen: false
    };
  }
  render() {
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <div style={{
            margin: '0 auto',
            display: 'table'
          }}>
            <Avatar
              name={
                this.props.name || ''
              }
              size="scale1400"
              src={this.props.profilephotoInput}
            />
          </div>

          <form onSubmit={e => e.preventDefault()}>
            <div className="form-item">
              {this.state.editOpen ? <FileUploader type="file"
                id="file" name="file"
                onDrop={e => {
                  this.props.uploadProfilePhoto({
                    file: e[0]
                  }).then((res) => {
                    this.setState({
                      editOpen: false
                    });
                    this.props.updateMeInput({
                      profilephoto: res.file.url
                    });
                  });
                }}/> : <div style={{
                  margin: '0 auto',
                  display: 'table',

                }}>
                  <span style={{
                    cursor: 'pointer'
                  }} onClick={() => {
                    this.setState({
                      editOpen: true
                    });
                  }}>
                    <Plus />
                  </span>
                </div>}
            </div>
            <div className="form-item">
              <Input type="text"
                onChange={e => {
                  this.props.updateMeInput({
                    name: e.target.value
                  });
                }}
                value={this.props.nameInput}
                placeholder="name">
              </Input>
            </div>
            <div className="form-item">
              <Input type="text"
                value={this.props.emailInput}
                onChange={e => {
                  this.props.updateMeInput({
                    email: e.target.value
                  });
                }}
                placeholder="email">
              </Input>
            </div>
            <div className="form-item">
              <Textarea
                value={this.props.summaryInput}
                onChange={e => {
                  this.props.updateMeInput({
                    summary: e.target.value
                  });
                }}
                placeholder="description" />
            </div>
            <div className="form-item">
              <Button onClick={() => {
                this.props.updateUser({
                  name: this.props.nameInput,
                  email: this.props.emailInput,
                  summary: this.props.summaryInput,
                  profilephoto: this.props.profilephotoInput,
                });
              }}>Update Profile</Button>
            </div>
            <div className="form-item">
              <Button onClick={() => {
                window.location = '/auth/logout'
              }}>Signout</Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(s => ({
  ...s.me.me,
  nameInput: s.me.meInput.name,
  emailInput: s.me.meInput.email,
  summaryInput: s.me.meInput.summary,
  profilephotoInput: s.me.meInput.profilephoto
}), {
  updateUser,
  uploadProfilePhoto,
  updateMeInput: data => ({
    type: 'ME_INPUT_UPDATE',
    payload: data
  })
})(Profile);
