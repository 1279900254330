import {React, Component} from 'react';
import Header from '../Header.js';
import {connect} from 'react-redux';
import {
  getPrograms, getDevices, refreshRobotState
} from '../../actions/xhr-actions';
import ProgramSelector from './ProgramSelector';
import ObjectTabs from './ObjectTabs';
import WorkField from './WorkField';
import WorkTray from './WorkTray';
import {Spinner} from 'baseui/spinner';

class ProgramIDE extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.getPrograms();
    this.props.getDevices();
    this.props.refreshRobotState();
  }
  render() {
    return (
      <div style={{
        height: '100vh'
      }}>
        <Header />
        <div style={{
          width: '100%',
          height: 'calc(100% - 69px)'
        }}>
          <div style={{
            width: '100%',
            height: '102px'
          }}>
            <span style={{
              margin: '12px',
              width: '400px',
              maxWidth: '100%',
              display: 'inline-block',
              verticalAlign: 'top'
            }}>
              <ProgramSelector />
            </span>
            <span style={{
              margin: '12px',
              display: 'inline-block'
            }}>
              {this.props.isLoadingProgram ||
                this.props.isLoadingPrograms ?
                <Spinner /> : null}
            </span>
          </div>
          <div style={{
            height: 'calc(100% - 104px)'
          }}>
            <div style={{
              height: 'calc(100% - 180px)',
              display: 'flex'
            }}>
              <div style={{
                width: '240px',
                height: '100%',
                backgroundColor: 'white',
                overflowX: 'scroll'
              }}>
                <ObjectTabs />
              </div>
              <div style={{
                display: 'inline-block',
                flex: '1',
                overflowY: 'scroll',
                backgroundColor: '#ccc'
              }}>
                <WorkField />
              </div>
            </div>
            <div style={{
              width: '100%',
              height: '180px',
              flex: '0 1 auto',
              backgroundColor: '#888'
            }}>
              <WorkTray />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  getPrograms, getDevices, refreshRobotState
})(ProgramIDE);
