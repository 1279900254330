function windowInfoMonitor(store) {
  store.dispatch({
    type: 'WINDOW_RESIZE',
    data: {
      width: window.innerWidth
    }
  });
  window.addEventListener('resize', () => {
    store.dispatch({
      type: 'WINDOW_RESIZE',
      data: {
        width: window.innerWidth
      }
    });
  });
}

export default windowInfoMonitor;
