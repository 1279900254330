import {Component} from 'react';
import {connect} from 'react-redux';
import {sendSocketMessage, initSocket} from '../../actions/socket-actions.js';
import {getDeviceFrameMetadata} from '../../actions/xhr-actions.js';
import moment from 'moment';

class CameraUI extends Component {
  constructor() {
    super();
    this.state = {
      refreshDate: Date.now(),
      frameMetadata: {}
    };
  }
  componentDidMount() {

    const fetchImage = () => {
      this.props.sendSocketMessage({
        type: 'getframe',
        deviceUuid: this.props.device.uuid
      });
      this.props.getDeviceFrameMetadata({
        uuid: this.props.device.uuid
      }).then(({res}) => {
        this.setState({
          frameMetadata: res
        });
      })
      this.setState({refreshDate: Date.now()});
    };
    this.props.initSocket().then(() => {fetchImage()});
    this.interval = setInterval(fetchImage, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  renderCapturedAt() {
    if (!this.state.frameMetadata.updated) {
      return null;
    }
    return (
      <div>
        <span>
          {`Captured: ${moment(this.state.frameMetadata.updated).fromNow()}`}
        </span>
      </div>
    );
  }
  render() {
    return (
      <div>
        <img
          id={this.state.refreshDate}
          crossorigin={""}
          src={this.state.frameMetadata.link} />
        {this.renderCapturedAt()}
      </div>
    );
  }
}

export default connect(s => s, {
  sendSocketMessage, initSocket, getDeviceFrameMetadata})(CameraUI);
