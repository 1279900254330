import {React, Component} from 'react';
import {connect} from 'react-redux';
import {
  TreeView,
  toggleIsExpanded
} from "baseui/tree-view";
import {
  openIDEWorkpanel
} from '../../actions/user-actions';
import DeviceOnlineStatusIndicator from '../DeviceOnlineStatusIndicator.js';
import DeviceSticker from '../DeviceSticker.js';

const objectTabs = [
  {
    id: 10,
    label: 'Devices',
    isExpanded: false,
    children: []
  },
  {
    id: 20,
    isExpanded: false,
    label: 'Program Factors',
    children: []
  },
  {
    id: 30,
    label: 'Files',
    isExpanded: false,
    children: []
  },
  {
    id: 40,
    label: 'Photos',
    isExpanded: false,
    children: []
  },
  {
    id: 50,
    label: 'Device Slots',
    isExpanded: false,
    children: []
  },
];

class ObjectTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectTabs: objectTabs
    };
  }
  componentDidMount() {

  }
  buildObjectTab() {
    const objectTabs = [
      ...this.state.objectTabs
    ];
    objectTabs[0].children = this.props.devices.map((d, i) => ({
      id: 101 + i,
      label: (
        <span>
          <DeviceOnlineStatusIndicator
            deviceState={d.state}
            statusText={false}
            timeOffline={false} />
            <span style={{
              lineHeight: '24px',
              paddingLeft: '6px',
              display: 'inline-block',
              verticalAlign: 'middle'
            }}>
              <DeviceSticker uuid={d.uuid}
                circleSize={18} />
            </span>
            <span style={{
              padding: '0 0 0 6px'
            }}>
              {(d.state && d.state.name) || d.name || ''}
            </span>
        </span>
      ),
      openPanel: `device$$${d.uuid}`,
      children: []
    }));
    objectTabs[1].children = [];
    if (this.props.selectedProgram) {
      if (this.props.selectedProgram.code) {
        objectTabs[1].children.push({
          id: 201,
          openPanel: 'programfactor$$code',
          label: 'Code'
        });
      }
      if (this.props.selectedProgram.pidGraphs) {
        objectTabs[1].children.push({
          id: 202,
          openPanel: 'programfactor$$pidgraph',
          label: 'PID Graphs'
        });
      }
      if (this.props.selectedProgram.config) {
        objectTabs[1].children.push({
          id: 203,
          openPanel: 'programfactor$$configs',
          label: 'Configs'
        });
      }
      if (this.props.selectedProgram.poses) {
        objectTabs[1].children.push({
          id: 204,
          openPanel: 'programfactor$$poses',
          label: 'Poses'
        });
      }
      objectTabs[2].children = this.props.selectedProgram.files.map((f, i) => ({
        id: 301 + i,
        label: `File ${i+1}`,
        openPanel: `file$$${f}`,
        children: []
      }));
      objectTabs[3].children = this.props.selectedProgram.images.map((f, i) => ({
        id: 401 + i,
        label: `Photos ${i+1}`,
        openPanel: `photo$$${f}`,
        children: []
      }))
      objectTabs[4].children = this.props.selectedProgram.resources.map((f, i) => ({
        id: 501 + i,
        label: `Slot ${i+1}`,
        openPanel: `resource$$${i}`,
        children: []
      }))
    }

    return objectTabs;
  }
  render() {
    return (
      <div>
      <TreeView
        data={this.buildObjectTab()}
        overrides={{
          TreeItem: {
            style: ({ $theme }) => ({
              cursor: "pointer"
            })
          }
        }}
        onToggle={node => {
          if (node.openPanel) {
            this.props.openIDEWorkpanel(node);
          }
          this.setState({
            objectTabs: toggleIsExpanded(
              this.state.objectTabs, node)
          });
        }}
      />
      </div>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  openIDEWorkpanel
})(ObjectTabs);
