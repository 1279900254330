import React, {Component} from 'react';
import {connect} from 'react-redux';
import {initSocket, sendSocketMessage, getSocket} from '../../actions/socket-actions';

class WifiSelector extends Component {
  componentDidMount() {
    this.props.sendSocketMessage({
      type: 'command-in',
      deviceUuid: this.props.device.uuid,
      data: 'wifiList'
    })
    const socket = getSocket();
    socket.addEventListener('message', msg => {
      const messageJSON = JSON.parse(msg);
      if (messageJSON.type === 'wifiList') {
        socket.send(JSON.stringify({
          type: 'message-ack',
          ackId: messageJSON.ackId,
          deviceUuid: this.props.device.uuid
        }));
      }
    });
  }
  render() {
    return (
      <div>
        <strong>{'Wifi'}</strong>
      </div>
    );
  }
}

export default connect(null, {
  sendSocketMessage
})(WifiSelector);
