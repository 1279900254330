import React, {Component} from 'react';
import Header from '../Header.js';
import '../../css/auth.css';
import {postPasswordReset} from '../../actions/xhr-actions.js';
import {connect} from 'react-redux';

class Reset extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header />
        <div className="auth-form-floater">
          <form onSubmit={e => {
            e.preventDefault();
            this.props.postPasswordReset({
              token: this.props.match.params.token,
              password: this.state.password
            });
          }}>
            <div className="form-item">
              <input type="password"
                placeholder="password"
                value={this.state.password}
                onChange={e => {
                  this.setState({
                    password: e.target.value
                  });
                }}>
              </input>
            </div>
            <div className="form-item">
              <button>Update Password</button>
            </div>
            <div className="form-item">
              <a href="/auth/login">Login</a>
            </div>
            <div className="form-item">
              <a href="/auth/signup">Signup</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(s => s, {
  postPasswordReset
})(Reset);
