import userReducer from './user.js';
import session from './session.js';
import me from './me.js';
import programIDE from './program-ide.js';
import programStore from './program-store.js';
import programEditor from './program-editor.js';
import windowInfo from './window-info.js';
import onlineDevices from './online-devices.js';
import {combineReducers} from 'redux';

export default combineReducers({
  user: userReducer,
  session,
  programEditor,
  windowInfo,
  me,
  programStore,
  programIDE,
  onlineDevices
});
