import {React, Component} from 'react';
import {connect} from 'react-redux';
import {closeIDEWorkpanel} from '../../actions/user-actions';
import Delete from 'baseui/icon/delete';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";

class WorkFieldPanelContainer extends Component {
  render() {
    return (
      <span
        style={{
          display: 'inline-block',
          margin: '12px',
          padding: '12px',
          backgroundColor: 'white',
          maxWidth: '480px',
          float: 'left',
          lineBreak: 'anywhere'
        }}>
        <Button
          kind={KIND.tertiary}
          size={SIZE.mini}
          shape={SHAPE.circle}
          onClick={() => {
            this.props.closeIDEWorkpanel({
              closePanel: this.props.panel
            });
          }}>
          <Delete />
        </Button>
        {this.props.children}
      </span>
    );
  }
}

export default connect(null, {
  closeIDEWorkpanel
})(WorkFieldPanelContainer);
