import {React, Component} from 'react';
import {
  init, loadModel, getMyDiagram, getSerializedState
} from '../gojs-pid-graph.js';
import { StatefulSlider } from "baseui/slider";

const defaultGraph = `{ "class": "GraphLinksModel",
"copiesArrays": true,
"copiesArrayObjects": true,
"linkFromPortIdProperty": "fromPort",
"linkToPortIdProperty": "toPort",
"nodeDataArray": [
{"name":"Feedback", "leftArray":[], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[ {"portId":"top0", "portColor":"#66d6d1"} ], "bottomArray":[], "key":-5, "loc":"80.27232480293705 378.0044271676925"},
{"name":"Derivative", "leftArray":[ {"portId":"left0", "portColor":"#d6effc"} ], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[], "bottomArray":[], "key":-6, "loc":"213 322"},
{"name":"Integral", "leftArray":[ {"portId":"left0", "portColor":"#66d6d1"} ], "rightArray":[ {"portId":"right0", "portColor":"#66d6d1"} ], "topArray":[], "bottomArray":[], "key":-8, "loc":"212 213"},
{"name":"Proportional", "leftArray":[ {"portId":"left0", "portColor":"#eaeef8"} ], "rightArray":[ {"portId":"right0", "portColor":"#fae3d7"} ], "topArray":[], "bottomArray":[], "key":-9, "loc":"208 117"},
{"name":"Sigma", "leftArray":[ {"portId":"left0", "portColor":"#fae3d7"} ], "rightArray":[ {"portId":"right0", "portColor":"#fadfe5"},{"portId":"right1", "portColor":"#fadfe5"},{"portId":"right2", "portColor":"#6cafdb"} ], "topArray":[], "bottomArray":[ {"portId":"bottom0", "portColor":"#6cafdb"} ], "key":-10, "loc":"80 212.8214015765036"},
{"name":"Set Point", "leftArray":[], "rightArray":[ {"portId":"right0", "portColor":"#ebe3fc"} ], "topArray":[], "bottomArray":[], "key":-11, "loc":"-30.863837598531482 213.09372637944068"},
{"name":"Sigma", "leftArray":[ {"portId":"left0", "portColor":"#ebe3fc"},{"portId":"left1", "portColor":"#fae3d7"},{"portId":"left2", "portColor":"#66d6d1"} ], "rightArray":[ {"portId":"right0", "portColor":"#fadfe5"} ], "topArray":[], "bottomArray":[ {"portId":"bottom0", "portColor":"#66d6d1"} ], "key":-7, "loc":"334.3051506316812 213.36788683727463"},
{"name":"Output", "leftArray":[ {"portId":"left0", "portColor":"#fae3d7"} ], "rightArray":[], "topArray":[], "bottomArray":[], "key":-12, "loc":"468.19997840406 214.23172443580603"}
],
"linkDataArray": [
{"from":-10, "to":-9, "fromPort":"right0", "toPort":"left0", "points":[118,202.8214015765036,132,202.8214015765036,162,202.8214015765036,162,117,160,117,170,117]},
{"from":-10, "to":-8, "fromPort":"right1", "toPort":"left0", "points":[118,212.8214015765036,136,212.8214015765036,164,212.8214015765036,164,213,160,213,174,213]},
{"from":-10, "to":-6, "fromPort":"right2", "toPort":"left0", "points":[118,222.8214015765036,128,222.8214015765036,160.5,222.8214015765036,160.5,322,161,322,175,322]},
{"from":-5, "to":-10, "fromPort":"top0", "toPort":"bottom0", "points":[80.27232480293705,340.0044271676925,80.27232480293705,326.0044271676925,80.27232480293705,291.41291437209804,80,291.41291437209804,80,260.8214015765036,80,250.8214015765036]},
{"from":-11, "to":-10, "fromPort":"right0", "toPort":"left0", "points":[7.136162401468518,213.09372637944068,21.136162401468518,213.09372637944068,21.136162401468518,212.8214015765036,21.136162401468518,212.8214015765036,32,212.8214015765036,42,212.8214015765036]},
{"from":-9, "to":-7, "fromPort":"right0", "toPort":"left0", "points":[246,117,256,117,285.15257531584064,117,285.15257531584064,203.36788683727463,282.3051506316812,203.36788683727463,296.3051506316812,203.36788683727463]},
{"from":-8, "to":-7, "fromPort":"right0", "toPort":"left1", "points":[250,213,260,213,283.15257531584064,213,283.15257531584064,213.36788683727463,274.3051506316812,213.36788683727463,296.3051506316812,213.36788683727463]},
{"from":-6, "to":-7, "fromPort":"right0", "toPort":"left2", "points":[251,322,265,322,268,322,268,223.36788683727463,286.3051506316812,223.36788683727463,296.3051506316812,223.36788683727463]},
{"from":-7, "to":-5, "fromPort":"bottom0", "toPort":"right0", "points":[334.3051506316812,251.36788683727463,334.3051506316812,265.3678868372746,334.3051506316812,378.0044271676925,233.28873771730912,378.0044271676925,132.27232480293705,378.0044271676925,118.27232480293705,378.0044271676925]},
{"from":-7, "to":-12, "fromPort":"right0", "toPort":"left0", "points":[372.3051506316812,213.36788683727463,382.3051506316812,213.36788683727463,415.2525645178706,213.36788683727463,415.2525645178706,214.23172443580603,416.19997840406,214.23172443580603,430.19997840406,214.23172443580603]}
]}`;

export default class PIDGraphEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    init();
    loadModel(JSON.parse(this.props.pidGraphs || defaultGraph));
    const myDiagram = getMyDiagram();
    myDiagram.addDiagramListener("ChangedSelection", (e) => {
      e.subject.each((sel) => {
        console.log('got selection', sel.data);
        this.setState({
          panelName: sel.data.name
        });
      });
    });
  }
  render() {
    return (
      <div style={{
        position: 'relative',
        width: '400px'
      }}>
        <label>{'PID Diagram'}</label>
        <div style={{
          width: '100%',
          backgroundColor: '#ccc',
          display: 'inline-block',
          verticalAlign: 'top'
        }}>
          <div style={{
            margin: '24px'
          }}>
            {this.state.panelName}
            <StatefulSlider />
          </div>
        </div>
        <div style={{
          height: '400px',
          position: 'relative'
        }}>
          <div style={{
            width: '100%',
            height: '100%'
          }} id="myDiagramDiv" />
        </div>
      </div>
    );
  }
}
