import {React, Component} from 'react';
import ReactCodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import {connect} from 'react-redux';
import {postProgramIDERun, saveProgramData} from '../../actions/xhr-actions';
import Play from '../icons/Play.js';
import Stop from '../icons/Stop.js';
import { Button, SIZE, SHAPE } from "baseui/button";

class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.props.code,
      running: 'stopped'
    };
  }
  renderRunningState() {
    if (this.state.running === 'running') {
      return (
        <div>
          <svg width="36" height="36">
            <circle cx="18" cy="18" r="18" fill="#ccc" />
          </svg>
          <span style={{
            lineHeight:"36px",
            verticalAlign: 'top',
            marginLeft: '12px'
          }}>
            {'Running'}
          </span>
        </div>
      );
    }
    if (this.state.running === 'stopped') {
      return (
        <div>
          <Stop color={"#ccc"} />
          <span style={{
            lineHeight:"36px",
            verticalAlign: 'top',
            marginLeft: '12px'
          }}>
            {'Stopped'}
          </span>
        </div>
      );
    }
  }
  render() {
    return (
      <div style={{
        width: '480px'
      }}>
        <label>{'Code'}</label>
        <ReactCodeEditor
          value={this.state.code}
          onValueChange={code => this.setState({ code })}
          highlight={code => highlight(code, languages.js)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            height: '100%'
          }}
        />
        <div style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: '100%',
          height: '100%'
        }}>
          <div style={{height: '35%', padding: '0 12px'}}>
            {this.renderRunningState()}
            <div style={{
              padding: '12px 0'
            }}>
              <span style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginRight: '24px'
              }}
                onClick={() => {
                  this.props.postProgramIDERun({
                    devResources: this.props.programIDE.devResources,
                    program: {
                      ...this.props.programIDE.selectedProgram,
                      code: this.state.code
                    }
                  });
                }}>
                <Play color={"#ccc"} />
              </span>
              <span style={{
                display: 'inline-block',
                marginRight: '24px',
                cursor: 'pointer'
              }}>
                <Stop color={"#ccc"} />
              </span>
              <span style={{
                verticalAlign: 'top'
              }}>
                <Button
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.saveProgramData({
                      ...this.props.programIDE.selectedProgram,
                      code: this.state.code
                    })
                  }}>
                  {'Save'}
                </Button>
              </span>
            </div>
          </div>

          <div style={{
            backgroundColor: '#eee',
            width: '100%',
            height: '65%'
          }}>
            <div>{'> Running'}</div>
            <div>{'> ping { “ts”: 300134 }'}</div>
            <div>{'> ping { “ts”: 300143 }'}</div>
            <div>{'> ping { “ts”: 300234 }'}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => s, {
  postProgramIDERun, saveProgramData
})(CodeEditor);
