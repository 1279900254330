import {Component} from 'react';

export default class Stop extends Component {
  render() {
    return (
      <svg width="25" height="25">
        <rect x="0" y="0" width="25" height="25"
          fill={this.props.color || "#eee"}/>
      </svg>
    )
  }
}
