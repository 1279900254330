import {React, Component} from 'react';
import ReactCodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';

export default class ConfigEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: this.props.config
    };
  }
  render() {
    return (
      <div>
        <label>{'Config'}</label>
        <ReactCodeEditor
          value={this.state.config}
          onValueChange={code => this.setState({ program: {...this.state.program, code } })}
          highlight={code => highlight(code, languages.js)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            height: '100%'
          }}
          />
        </div>
    );
  }
}
