const API_URL = 'https://robots-gateway.uc.r.appspot.com';

const urlSwitch = () => {
  if (window.origin === 'http://localhost:3000') {
    return 'http://localhost:8080';
  }
  return API_URL;
}
export function uploadCreator(url) {
  return req => dispatch => {
    var formData = new FormData();
    formData.append("file", req.file, req.file.filename);
    Object.keys(req.body).map(k => {
      formData.append(k, req.body[k]);
    });
    return fetch(`${urlSwitch()}${url}`, {
      method: 'POST',
      body: formData
    })
    .then(response => response.json());
  };
}

export const uploadFile = uploadCreator(
  '/api/upload'
)

export const uploadMiscFile = uploadCreator(
  '/api/misc-upload'
)

export const uploadProfilePhoto = uploadCreator(
  '/profile-photo/upload'
)
