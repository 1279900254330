const DEFAULT_STATE = {
  width: 900
}

function windowInfo(oldState = DEFAULT_STATE, action) {
  let state = {...oldState};
  switch (action.type) {
    case 'WINDOW_RESIZE':
      return {
        ...state,
        width: action.data.width
      };
    default:
      return state;
  }
}

export default windowInfo;
