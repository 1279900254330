import {createRef, Component} from 'react';
import {connect} from 'react-redux';
import {
  initSocket, sendSocketMessage
} from '../../actions/socket-actions';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";
import { Input } from "baseui/input";

function angle(cx, cy, ex, ey) {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  return theta - Math.PI;
}

const angleDiff = -90;
const motorWidth = 160;
const motorDiameter = 100;
const motorMid = motorWidth / 2;
const motorRadius = motorDiameter / 2;
const slowDistance = 25;
const fastDistance = 120;
const xFastDistance = 70;

class ButtonPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gripX: motorMid,
      gripY: motorMid - motorRadius,
      controllerAngle: 270
    }
    this.dirLine = createRef();
    this.motorBox = createRef();
    this.gripRef = createRef();
    this.timer = null;
    this.handleDocumentMouseUp = this.handleDocumentMouseUp.bind(this);
    this.handleDocumentMouseMove = this.handleDocumentMouseMove.bind(this);
  }
  generateCommandHandler(command) {
    return () => {
      return this.props.sendSocketMessage({
        type: 'command-in',
        deviceUuid: this.props.device.uuid,
        data: command
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleDocumentMouseUp);
    document.removeEventListener('mousemove', this.handleDocumentMouseMove);
    clearInterval(this.timer)
  }
  handleDocumentMouseUp(e) {
    this.setState({
      holdingSpinner: false
    });
    const midCircleBox = this.motorBox.current.getBoundingClientRect();
    const gripCircle = this.gripRef.current.getBoundingClientRect();
    const desiredAngle = angle(gripCircle.x - motorMid, gripCircle.y - motorMid,
      midCircleBox.x + motorRadius, midCircleBox.y + motorRadius);
    const controllerAngle = Math.floor(desiredAngle * -180 / Math.PI);
    this.props.sendSocketMessage({
      type: 'command-in',
      deviceUuid: this.props.device.uuid,
      data: `gotoangle:${controllerAngle}`
    });
    clearInterval(this.timer);
  }
  handleDocumentMouseMove(e) {
    if (this.state.holdingSpinner) {
      const midCircleBox = this.motorBox.current.getBoundingClientRect();
      const desiredAngle = angle(e.clientX - motorMid, e.clientY - motorMid,
        midCircleBox.x, midCircleBox.y);
      const controllerAngle = Math.floor(desiredAngle * -180 / Math.PI);
      this.setState({
        gripX: Math.floor((Math.cos(desiredAngle) * motorRadius) + (motorWidth/2)),
        gripY: Math.floor((Math.sin(desiredAngle) * motorRadius) + (motorWidth/2)),
        controllerAngle
      });
    }
  }
  render() {
    return (
      <div style={{
        height: '280px',
        paddingTop: '12px',
        position: 'relative',
        overflow: 'auto',
        display: this.props.display ? '' : 'none'
      }}>
        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            style={{marginRight: '8px'}}
            shape={SHAPE.circle}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle - xFastDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("xfastleft")();
            }}>
            {'<<<'}
          </Button>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle - fastDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("fastleft")();
            }}>
            {'<<'}
          </Button>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle - slowDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("left")();
            }}>
            {'<'}
          </Button>
        </div>
        <div style={{display: 'inline-block', marginRight: '8px'}}>
          <style>{`svg {pointer-events: none;}
          svg * {pointer-events: all;}`}</style>
          <div>
            <span>
              <svg width={motorWidth} height={motorWidth}>
                <rect
                  ref={this.motorBox}
                  width={motorWidth} height={motorWidth} fill="#ccc"></rect>
                <g
                  onClick={(e) => {
                    const midCircleBox = this.motorBox.current.getBoundingClientRect();
                    const desiredAngle = angle(e.clientX - motorMid, e.clientY - motorMid,
                      midCircleBox.x, midCircleBox.y);
                    const controllerAngle = Math.floor(desiredAngle * -180 / Math.PI);
                    this.setState({
                      gripX: Math.floor((Math.cos(desiredAngle) * motorRadius) + (motorWidth/2)),
                      gripY: Math.floor((Math.sin(desiredAngle) * motorRadius) + (motorWidth/2)),
                      controllerAngle
                    });
                    this.props.sendSocketMessage({
                      type: 'command-in',
                      deviceUuid: this.props.device.uuid,
                      data: `gotoangle:${controllerAngle}`
                    })
                  }}
                  >
                  <circle
                    cx={motorWidth/2} cy={motorWidth/2} r={motorRadius} fill="none" stroke="#888" strokeWidth="4" />
                </g>
                <line
                  strokeWidth="4" stroke="#888" x1={motorWidth/2} y1={motorWidth/2}
                  x2={motorWidth/2} y2={motorMid - motorRadius}/>
                <line
                  ref={this.dirLine}
                  strokeWidth="4" stroke="red" x1={motorWidth/2} y1={motorWidth/2}
                  x2={this.state.gripX} y2={this.state.gripY}/>
                <g
                  onMouseDown={() => {
                    document.addEventListener('mouseup', this.handleDocumentMouseUp);
                    document.addEventListener('mousemove', this.handleDocumentMouseMove);
                    this.setState({
                      holdingSpinner: true
                    });
                    this.timer = setInterval(() => {
                      const midCircleBox = this.motorBox.current.getBoundingClientRect();
                      const gripCircle = this.gripRef.current.getBoundingClientRect();
                      const desiredAngle = angle(gripCircle.x - motorMid, gripCircle.y - motorMid,
                        midCircleBox.x, midCircleBox.y);
                      const controllerAngle = Math.floor(desiredAngle * -180 / Math.PI);
                      this.props.sendSocketMessage({
                        type: 'command-in',
                        deviceUuid: this.props.device.uuid,
                        data: `gotoangle:${controllerAngle}`
                      });
                    }, 1000)
                  }}>
                  <circle cx={this.state.gripX} cy={this.state.gripY} fill="none" r="12" stroke="black"
                    strokeWidth="4" ref={this.gripRef} />
                </g>
              </svg>
            </span>
          </div>
        </div>
        <div style={{display: 'inline-block', width: '30%', verticalAlign: 'top'}}>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle + slowDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("right")();
            }}>
            {'>'}
          </Button>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle + fastDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("fastright")();
            }}>
            {'>>'}
          </Button>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={() => {
              const desiredAngle = (this.state.controllerAngle + xFastDistance) % 360;
              const desiredRadians = desiredAngle * Math.PI / 180;
              this.setState({
                gripX: Math.floor((Math.cos(desiredRadians) * motorRadius) + (motorWidth/2)),
                gripY: Math.floor((Math.sin(desiredRadians) * motorRadius) + (motorWidth/2)),
                controllerAngle: desiredAngle
              });
              this.generateCommandHandler("xfastright")();
            }}>
            {'>>>'}
          </Button>
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            style={{marginRight: '8px'}}
            onClick={this.generateCommandHandler("stop")}>
            {'X'}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  initSocket, sendSocketMessage
})(ButtonPanel);
