import { Input } from "baseui/input";
import {Button, SIZE, SHAPE, KIND} from 'baseui/button';
import {React, Component} from 'react';

export default class DeviceNameEditable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.name
    };
  }
  render() {
    if (this.state.editable) {
      return (
        <span>
          <Input
            onChange={(e) => {
              this.setState({value: e.target.value})
            }}
            value={this.state.value} />
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={() => {
              const prms = this.props.onSave(this.state.value);
              if (prms) {
                this.setState({loading: true});
                prms.then(() => {
                  this.setState({
                    loading: false,
                    editable: false
                  })
                });
              }
              this.setState({editable: false})
            }}>
              Save
            </Button>
            <Button
              size={SIZE.mini}
              kind={KIND.secondary}
              shape={SHAPE.pill}
              onClick={() => {
                this.setState({
                  editable: false
                });
              }}>
                Cancel
              </Button>
          </span>
      );
    }
    return (
      <span>
        <span
          style={{
            lineHeight: '36px',
            cursor: 'pointer'
          }}
          onClick={() => {
            this.setState({
              editable: true
            });
          }}>
          {this.state.value}
        </span>
      </span>
    );
  }
}
