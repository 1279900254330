import {Component} from 'react';
import { PTYWSAttachAddon } from '../../pty-attach-addon';
import {Terminal} from 'xterm';
import {connect} from 'react-redux';
import {initSocket, sendSocketMessage} from '../../actions/socket-actions';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";

class SSH extends Component {
  getTerminalId() {
    return `terminal-${this.props.device.uuid}`;
  }

  componentDidMount() {
    this.props.initSocket().then((socket) => {
      this.attachAddon = new PTYWSAttachAddon(
        socket,
        {
          deviceUuid: this.props.device.uuid
        }
      );

      this._myTerminal = new Terminal();
      this._myTerminal.loadAddon(this.attachAddon);
      this._myTerminal.open(document.getElementById(this.getTerminalId()));
      this._myTerminal.write('[01;32mpi@raspberrypi[00m:[01;34m~ $[00m ');
    });
  }
  componentWillUnmount() {
    this._myTerminal && this._myTerminal.dispose();
    this.attachAddon && this.attachAddon.dispose();
  }
  render() {
    return (
      <div style={{
        display: this.props.display ? '' : 'none'
      }}>
        <div style={{overflow: 'auto'}}
          id={this.getTerminalId()}>
        </div>
        <span style={{
          marginLeft: '12px',
          marginTop: '6px',
          display: 'inline-block'
        }}>
          <Button
            size={SIZE.mini}
            shape={SHAPE.pill}
            onClick={(e) => {
              e.preventDefault();
              this.props.sendSocketMessage({
                type: 'pty-in',
                data: 'cd /home/pi/orobot-firmware/ && tail -fn 50 tmp/run.log\r',
                deviceUuid: this.props.device.uuid
              });
            }}>
            {'Tail Logs Cmd'}
          </Button>
        </span>
      </div>
    );
  }
}

export default connect(null, {
  initSocket, sendSocketMessage
})(SSH);
