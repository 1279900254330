import isLoadingReducer from './helpers/is-loading';
const DEFAULT_STATE = {
  homePrograms: [],
  myRobots: [],
  devices: {},
  hasFirstLoadedDevices: false
};

export default function (oldState = DEFAULT_STATE, action) {
  let state = {...oldState};
  state = isLoadingReducer('GET_HOME_PROGRAMS', 'Programs')(state, action);
  state = isLoadingReducer('GET_ROBOTS', 'Robots')(state, action);
  state = isLoadingReducer('GET_DEVICES', 'Devices')(state, action);
  switch(action.type) {
  case 'GET_HOME_PROGRAMS_SUCCESS':
    return {
      ...state,
      homePrograms: action.payload.res
    };
  case 'GET_PROGRAMS_SUCCESS':
    return {
      ...state,
      homePrograms: action.payload.res
    };
  case 'GET_ROBOTS_SUCCESS':
    return {
      ...state,
      myRobots: action.payload.res
    };
  case 'GET_DEVICES_SUCCESS':
    return {
      ...state,
      hasFirstLoadedDevices: true,
      devices: action.payload.res.reduce((a, d) => {
        a[d.uuid] = d;
        return a;
      }, {})
    };
  default:
    return state;
  }
}
