import {React, Component} from 'react';
import {connect} from 'react-redux';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";
import Delete from 'baseui/icon/delete';
import {unattachDevDevice} from '../../actions/user-actions.js';
import DeviceMetadata from '../OnlineDeviceControls/DeviceMetadata.js';

class WorkTray extends Component {
  render() {
    return (
      <div style={{
        padding: '0 12px',
        overflowY: 'scroll'
      }}>
        <div style={{
          whiteSpace: 'nowrap'
        }}>
          {this.props.devResources.map((devRes, i) => {
            const device = this.props.devices.find(d => d.uuid === devRes)
            return (
              <span style={{
                backgroundColor: 'white',
                display: 'inline-block',
                margin: '12px 12px 0 0'
              }}>
                <Button
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  shape={SHAPE.circle}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.unattachDevDevice({
                      uuid: devRes
                    });
                  }}>
                  <Delete />
                </Button>
                <span>
                  {`Resource #${i}`}
                </span>
                <div style={{
                  margin: '0 24px 24px 24px'
                }}>
                  <DeviceMetadata device={device} />
                </div>
              </span>
            )
          })}
        </div>
      </div>
    );
  }
}

export default connect(s => ({
  ...s.programIDE
}), {
  unattachDevDevice
})(WorkTray);
