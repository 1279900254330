import {
  postDeviceState,
  deleteDevices
} from '../../actions/xhr-actions';
import React, {Component} from 'react';
import moment from 'moment';
import { Button, SIZE, SHAPE, KIND } from "baseui/button";
import DeviceNameEditable from './DeviceNameEditable.js';
import {connect} from 'react-redux';
import DeviceOnlineStatusIndicator from '../DeviceOnlineStatusIndicator';
import {userFlags} from '../../helpers/user-flags.js';
import { Input } from "baseui/input";
import DeviceSticker from '../DeviceSticker.js';
import Show from 'baseui/icon/show';
import calcHexColorFromUuid from '../../uuid-to-color.js';

class DeviceMetadata extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderOnline() {
    return (
      <div>
        <DeviceOnlineStatusIndicator deviceState={this.props.device.state} />
      </div>
    );
  }

  renderLastHeartbeat() {
    if (!this.props.device.state) {
      return null;
    }
    let payloadObject = {};
    try {
      payloadObject = JSON.parse(this.props.device.state || '{}');
    } catch (e) {

    }
    return (
      <span style={{
        display: 'inline-block',
        verticalAlign: 'top',
        marginLeft: '24px'
      }}>
        {Object.keys(payloadObject).map(k => {
          if (typeof payloadObject[k] !== 'string') {
            return null;
          }
          return (
            <div>
              <strong>{`${k}: `}</strong>
              {payloadObject[k]}
            </div>
          );
        })}
      </span>
    )
  }
  renderProfileIcon(circleSize = 90) {
    return (
      <DeviceSticker uuid={this.props.device.uuid}
        circleSize={circleSize} />
    );
  }
  render() {
    return (
      <div style={{
        position: 'relative'
      }}>
        {this.renderProfileIcon()}
        <span style={{
          display: 'inline-block',
          marginLeft: '24px'
        }}>
          <DeviceNameEditable
            name={(this.props.device.state || {}).name || 'Name undefined'}
            onSave={(s) => {
              return this.props.postDeviceState({
                deviceUuid: this.props.device.uuid,
                payloadJSON: JSON.stringify({
                  ...(this.props.state || {}),
                  name: s
                })
              });
            }} />
          {this.renderOnline()}
          <div>
            {this.props.device.state.type === 'wifi-camera' ?
              <Show style={{
                marginRight: '6px'
              }} /> : null}
            {`Created ${moment(this.props.device.createdAt).format('MM/DD/YY')}`}
          </div>
          {this.renderLastHeartbeat()}
        </span>
      </div>
    );
  }
}

export default connect(s => s, {
  postDeviceState, deleteDevices
})(DeviceMetadata);
