import {Component} from 'react';
import Header from './Header.js';
import '../css/shop.css';
import {
  Card,
  StyledBody,
  StyledAction
} from "baseui/card";
import { Input } from "baseui/input";
import { PaymentCard } from "baseui/payment-card";
import { Textarea } from "baseui/textarea";
import { Button } from "baseui/button";

export class SalesCard extends Component {
  render() {
    return (
      <Card
        overrides={{Root: {style: {
          width: '186px',
          margin: '48px 12px 0 0',
          cursor: 'pointer'}}}}
        onClick={this.props.onClick}
        headerImage={
          this.props.thumb
        }
        title={this.props.productName}>
        <StyledBody>
          <div>
            <div>
              <strong>{`$${this.props.price}`}</strong>
            </div>
          </div>
        </StyledBody>
      </Card>
    )
  }
}

export default class Shop extends Component {
  constructor() {
    super();
    this.state = {
      products: [{
        productId: '01',
        thumb: 'https://storage.googleapis.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%202.42.13%20PM.png',
        productName: '50mm Robot Motor and Charger Dev Edition',
        price: 299
      }, {
        productId: '04',
        productName: '3D Printer',
        thumb: 'https://storage.googleapis.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.29.58%20PM.png',
        price: 299
      }, {
        productId: '05',
        productName: 'Open Robotics T Shirt S',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.11.35%20PM.png',
        price: 29
      }, {
        productId: '09',
        productName: 'Open Robotics T Shirt M',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.11.35%20PM.png',
        price: 29
      }, {
        productId: '10',
        productName: 'Open Robotics T Shirt L',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.11.35%20PM.png',
        price: 29
      }, {
        productId: '11',
        productName: 'Open Robotics T Shirt XL',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.11.35%20PM.png',
        price: 29
      }, {
        productId: '06',
        productName: 'Open Robotics Hat',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.07.06%20PM.png',
        price: 39
      }, {
        productId: '07',
        productName: 'Open Robotics Coffee Mug',
        thumb: 'https://storage.cloud.google.com/openrobots.appspot.com/public/Screen%20Shot%202021-02-03%20at%201.08.32%20PM.png',
        price: 19
      }, {
        productId: '09',
        productName: 'Open Robotics Sticker Pack',
        thumb: 'https://storage.googleapis.com/orobot-public/images/stickers.png',
        price: 19
      }],
      cart: {}
    };
  }
  render() {
    return (
      <div>
        <Header />
        <div style={{position: 'relative'}}>
          <div style={{
            margin: '24px',
            width: '60%',
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap'
          }}>
            {this.state.products.map(p => {
              return (
                <SalesCard
                  onClick={() => {
                    const newCount = ((this.state.cart[p.productId] || {}).ct || 0) + 1;
                    if (newCount === 0) {
                      this.setState({
                        cart: {
                          ...this.state.cart,
                          [p.productId]: null
                        }
                      });
                    } else {
                      this.setState({
                        cart: {
                          ...this.state.cart,
                          [p.productId]: {
                            product: p,
                            ct: newCount
                          }
                        }
                      });
                    }
                  }}
                  productName={p.productName}
                  price={p.price}
                  thumb={p.thumb} />
              );
            })}
          </div>
          <div style={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: '30%'
          }}>
            <div style={{minHeight: '60px', marginBottom: '24px'}}>
              {Object.keys(this.state.cart).map(k => {
                return (
                  <div>

                    <div>
                      <span className="cart-item-name">
                        {this.state.cart[k].product.productName}
                      </span>
                      {` x${this.state.cart[k].ct}. `}
                      <span className="cart-item-action"
                        onClick={() => {
                          this.setState({
                            cart: {
                              ...this.state.cart,
                              [k]: {
                                ...this.state.cart[k],
                                ct: this.state.cart[k].ct + 1
                              }
                            }
                          })
                        }}>
                        {'Add'}
                      </span>
                      <span className="cart-item-action"
                        onClick={() => {
                          if (this.state.cart[k].ct === 1) {
                            const newCart = {
                              ...this.state.cart
                            };
                            delete newCart[k];
                            this.setState({
                              cart: newCart
                            });
                          } else {
                            this.setState({
                              cart: {
                                ...this.state.cart,
                                [k]: {
                                  ...this.state.cart[k],
                                  ct: this.state.cart[k].ct - 1
                                }
                              }
                            });
                          }

                        }}>
                        {'Remove'}
                      </span>
                    </div>
                    <div>
                      {`Price $${
                        this.state.cart[k].ct * this.state.cart[k].product.price
                      }`}
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div><label>{'Name'}</label></div>
              <Input type="text" />
            </div>
            <div>
              <div><label>{'Credit Card Number'}</label></div>
              <PaymentCard type="text" />
            </div>
            <div>
              <div><label>{'Zipcode'}</label></div>
              <Input type="text" />
            </div>
            <div>
              <div><label>{'Shipping Address'}</label></div>
              <Textarea />
            </div>
            <Button>
              {'Submit'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
