import {Component} from 'react';
import Header from '../Header';
import {connect} from 'react-redux';

const UnityHost = 'https://storage.googleapis.com/orobot-misc-files';

class RobotBuilder extends Component {
  componentDidMount() {
    Promise.all([
      new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = `${UnityHost}/WebBuild/Build/UnityLoader.js`;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      }),
      new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = `${UnityHost}/WebBuild/TemplateData/UnityProgress.js`;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      })
    ]).then(() => {
      this.unityInstance = window.UnityLoader.instantiate(
      "unityContainer", `${UnityHost}/WebBuild/Build/WebBuild.json`, {
        onProgress: window.UnityProgress});
    })
  }
  render() {
    return (
      <div>
        <Header />
        <div style={{
          display: 'table',
          margin: '0 auto',
          width: '1024px',
          height: '700px'
        }}>
          <div className="webgl-content">
            <div id="unityContainer" style={{
              width: '960px',
              height: '600px'
            }}></div>
            <div className="footer">
              <h1>{'Controls'}</h1>
              <h3>{'Click drag: Rotate Camera'}</h3>
              <h3>{'A: Rotate left'}</h3>
              <h3>{'D: Rotate right'}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(s => s, {})(RobotBuilder);
