import {uuid} from 'uuidv4';
import {
  createSession,
  getMe
} from './actions/xhr-actions';
const orobotSessKey = '_osess';

const unauthenticatedRoutes = [
  '/auth/signup'
  ,'/auth/login'
  ,'/auth/reset'
  ,'/auth/forgot'
  ,'/'
  ,'/o/robots'
  ,'/o/program-view'
];

function matchesRoutes(path) {
  return unauthenticatedRoutes.reduce((a, p) => {
    return a || window.location.pathname.indexOf(p) > -1;
  }, false);
}
/* eslint-disable no-restricted-globals */
export default function sessionCookieInit(store) {
  let sessVal = '';
  const oSessCookie = document.cookie.split(';')
    .find(c => c.indexOf(orobotSessKey) > -1)

  if (oSessCookie) {
    sessVal = oSessCookie.split('=')[1];
  }
  if (sessVal.trim().length < 1 &&
    !matchesRoutes(window.location.pathname)) {
    window.location = '/auth/signup'
  } else if (!matchesRoutes(window.location.pathname)) {
    store.dispatch(getMe()).catch(() => {
      window.location = '/auth/signup'
    });
  }
  return sessVal;
}
